import React, { useContext } from 'react';
import Select, { components } from 'react-select';
import { props } from './SearchBoxPlace.props';
import { StyledMarker } from './SearchBoxPlace.styled';
import { ThemeContext } from 'styled-components';
import _ from 'lodash';
import { AirportNames, BusNames, OfficeNames, PointNames, PortNames } from 'utils/constants';
import withSettingsContext from 'SettingsContext';
import { MODERN_THEME } from 'styled/constants';

const Option = props => {
  const { data } = props;

  const icons = category => {
    if (category) {
      if (AirportNames.some(i => i === category)) {
        return 'fa-plane';
      }
      if (OfficeNames.some(i => i === category)) {
        return 'fa-building';
      }
      if (PortNames.some(i => i === category)) {
        return 'fa-ship';
      }
      if (PointNames.some(i => i === category)) {
        return 'fa-map-marker-alt';
      }
      if (BusNames.some(i => i === category)) {
        return 'fa-bus';
      }
    }
    return 'fa-building';
  };

  const category = data.category.toLowerCase();
  const airport = AirportNames.some(i => i === category);

  const IATA = data.branchOfficeIATACode && data.showIATA ? ` (${data.branchOfficeIATACode})` : '';

  const name = airport ? `${data.name}${IATA}` : data.name;

  return (
    <components.Option {...props}>
      <i className={'fas mr-2 ' + icons(category)} />
      {name}
      {data.showCity && (
        <span
          style={{
            display: 'block',
            fontSize: '0.9em',
            marginLeft: '20px',
            textTransform: 'capitalize',
          }}
        >
          {data.city.toLowerCase()} - {data.country}
        </span>
      )}
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <StyledMarker className="fas fa-map-marker-alt" />
    {children}
  </components.ValueContainer>
);

const SearchBoxPlace = props => {
  const items = [];
  if (props.data != null) {
    props.data.forEach(element => {
      const item = { ...element };
      item.value = `${item.rentlyEndpointName}${item.id}`;
      item.label = props.showCity ? `${item.name} - ${item.city}` : item.name;
      item.showCity = props.showCity;
      item.showIATA = props.showIATA;
      items.push(item);
    });
  }

  const themeContext = useContext(ThemeContext);
  const sortedItems = props.useBackendSort ? items : _.sortBy(items, i => `${i.country}-${i.name}`);

  const filterOptions = ({ data }, input) => {
    const { branchOfficeIATACode, name, city, country } = data;
    if (input) {
      const keywords = input.toLowerCase();
      const branchName = name ? name.toLowerCase() : '';
      const IATA = branchOfficeIATACode ? branchOfficeIATACode.toLowerCase() : '';
      const citysearch = city ? city.toLowerCase() : '';
      const countrysearch = country ? country.toLowerCase() : '';

      return `${branchName}-${IATA}-${citysearch}-${countrysearch}`.includes(keywords);
    }
    return true;
  };

  const defaultStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: 0,
      border: 'solid 1px #e7e7e7',
      height: '48px',
      // This line disable the blue border
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        border: state.isFocused,
      },
    }),
    input: base => ({
      ...base,
      marginLeft: '10px',
    }),
    placeholder: base => ({
      ...base,
      marginLeft: '20px',
    }),
    singleValue: base => ({
      ...base,
      marginLeft: '20px',
    }),
  };

  const modernStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: 10,
      border: 'solid 1px #D8DCE2',
      height: '48px',
      position: 'relative',
      bottom: '3px',
      boxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.37)',
      // This line disable the blue border
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        border: state.isFocused,
      },
    }),
    menu: base => ({
      ...base,
      zIndex: 1000,
    }),
    input: base => ({
      ...base,
      marginLeft: '10px',
    }),
    placeholder: base => ({
      ...base,
      marginLeft: '20px',
    }),
    singleValue: base => ({
      ...base,
      marginLeft: '20px',
    }),
  };

  const styles = props.settings.theme.template === MODERN_THEME ? modernStyles : defaultStyles;

  return (
    <Select
      name={props.name}
      inputId={props.name}
      isClearable
      placeholder={props.placeholder}
      value={props.placeSelected}
      options={sortedItems}
      components={{
        Option,
        ValueContainer,
      }}
      onChange={selectedOption => props.placeChangeHandler(selectedOption)}
      filterOption={filterOptions}
      styles={styles}
      theme={th => ({
        ...th,
        colors: {
          ...th.colors,
          primary: 'lightgray',
          primary25: themeContext.colors.buttonPrimaryBg,
        },
        zIndex: '9999',
      })}
    />
  );
};

SearchBoxPlace.propTypes = props;
export default withSettingsContext(SearchBoxPlace);
