/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import queryString from 'query-string';
import moment from 'moment';
import { isEmpty } from 'lodash';
import TranslatedComponent from 'components/TranslatedComponent';
import { tlink } from 'utils/translationHelper';
import { getValidPromotionCode } from './../../services/api';
import { setWebCurrencyAction } from 'actions';
import {
  getSchedule,
  calculateStartDate,
  calculateEndDate,
  isDayAvailable,
  _calculatePickDropHours,
  parseHourToMoment,
} from 'utils/branchOfficeHelper';
import DefaultSearchForm from './Vertical';
import ModernSearchForm from './Horizontal';
import LuxurySearchForm from './Luxury';
import { connect } from 'react-redux';
import { isCompanyUser } from 'utils/dataHelper';

const DEFAULT_MINIMUM_STAY = 2; // nights

const transformPlaceToSelectOption = (place, t) => ({
  t,
  ...place,
  label: place ? place.name : t('selectPickUpPlace'),
  value: place ? place.id : '',
});

const getFilteredPlaces = (places, configurations, schedules) => {
  let placesList = places ? [...places] : [];

  if (configurations.filteredBranchOffices) {
    const { includedBranchIds, excludedBranchIds } = configurations.filteredBranchOffices;

    if (includedBranchIds && includedBranchIds.length > 0) {
      placesList = placesList.filter(place => includedBranchIds.includes(place.branchOfficeId));
    }
    if (excludedBranchIds && excludedBranchIds.length > 0) {
      placesList = placesList.filter(place => !excludedBranchIds.includes(place.branchOfficeId));
    }
  }

  if (configurations.filteredPlaces) {
    const { includedPlaceIds, excludedPlaceIds } = configurations.filteredPlaces;

    if (includedPlaceIds && includedPlaceIds.length > 0) {
      placesList = placesList.filter(place => includedPlaceIds.includes(place.id));
    }
    if (excludedPlaceIds && excludedPlaceIds.length > 0) {
      placesList = placesList.filter(place => !excludedPlaceIds.includes(place.id));
    }
  }

  if (!schedules) return [];

  return placesList.filter(place => {
    let scheduleConf = getSchedule(schedules, place.branchOfficeId);
    const scheduleIsEmpty = scheduleConf
      ? Object.values(scheduleConf.schedule).every(isEmpty)
      : true;

    return !scheduleIsEmpty;
  });
};

const getFilteredPickUpPlaces = (places, t) => {
  // Remove places that allows only return
  return places.filter(place => place?.availableOperationOptions !== 'ReturnOnly');
};

const getFilteredDropOffPlaces = (places, t) => {
  // Remove places that allows only pick up
  return places.filter(place => place?.availableOperationOptions !== 'DeliveryOnly');
};

const isADropOffPlace = (place, t) => place?.availableOperationOptions !== 'DeliveryOnly';

class SearchForm extends TranslatedComponent {
  static propTypes = {
    setSearchCarParams: PropTypes.func.isRequired,
    fetchBookeableCars: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.object,
    searchText: PropTypes.string,
    i18n: PropTypes.object,
    categories: PropTypes.array,
    className: PropTypes.string,
    showCity: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const {
      router,
      t,
      i18n,
      searchCars,
      featureFlags,
      settings,
      profile,
      bookingsConfiguration,
    } = props;
    const searchQueryStrings = queryString.parse(router.location.search);
    const {
      dateFrom,
      hourFrom,
      dateTo,
      hourTo,
      ilimitedKm,
      categories,
      promotionCode,
      customPromotionId,
      commercialAgreementCode,
      agencyGuid,
    } = searchQueryStrings;

    this.submitSearch = this.submitSearch.bind(this);
    this.submitLuxurySearch = this.submitLuxurySearch.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.dropOffSelect = this.dropOffSelect.bind(this);
    this.pickUpSelect = this.pickUpSelect.bind(this);
    this.toggleDropInput = this.toggleDropInput.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);
    this.pickUpHourSelection = this.pickUpHourSelection.bind(this);
    this.dropOffHourSelection = this.dropOffHourSelection.bind(this);
    this.onPromotionCodeChange = this.onPromotionCodeChange.bind(this);
    this.handleKmFilter = this.handleKmFilter.bind(this);
    this.disableSubmit = this.disableSubmit.bind(this);
    this.filteredDropPlaces = this.filteredDropPlaces.bind(this);
    this.handleDriverAgeChange = this.handleDriverAgeChange.bind(this);
    this.onCommercialAgreementChange = this.onCommercialAgreementChange.bind(this);

    this.state = {
      loading: false,
      isSelectingStartDate: true,
      errorMessage: '',
      isHome:
        tlink('__Routes.search', t, i18n, null, settings.configurations.langConfig) !==
        router.location.pathname,
      searchQueryStrings,
      rangeDatePicker: {
        orientation:
          window.screen.width < parseFloat(props.theme.screens.md.replace('px', ''))
            ? 'vertical'
            : 'horizontal',
        withFullScreenPortal:
          window.screen.width < parseFloat(props.theme.screens.md.replace('px', '')),
        focus: null,
      },
      pickUp: {
        date: dateFrom || searchCars.params.dateFrom || null,
        hour: hourFrom || searchCars.params.hourFrom,
        place: null,
        moment: dateFrom
          ? this.parseDate(dateFrom)
          : searchCars.params.dateFrom
          ? this.parseDate(searchCars.params.dateFrom)
          : null,
      },
      dropOff: {
        date: dateTo || searchCars.params.dateTo || null,
        hour: hourTo || searchCars.params.hourTo,
        place: null,
        moment: dateTo
          ? this.parseDate(dateTo)
          : searchCars.params.dateTo
          ? this.parseDate(searchCars.params.dateTo)
          : null,
        checkoutTime: null,
      },
      maximumStay:
        dateFrom || searchCars.params.dateTo
          ? this.getMaximumStay(moment(dateFrom || searchCars.params.dateTo))
          : null,
      minStayLength:
        settings?.configurations.minBookingLength ??
        bookingsConfiguration?.minDays ??
        DEFAULT_MINIMUM_STAY,
      showDifferentDropPlace: false,
      kmFilter: {
        selected:
          ilimitedKm && ilimitedKm.length > 0
            ? ilimitedKm === 'true'
            : featureFlags.activeIlimitedKm || searchCars.params.ilimitedKm || false,
      },
      categories: categories ? categories : searchCars.params.categories || [],
      showInvalidPromotionMessage: false,
      promotionCode: promotionCode || null,
      customPromotionId: customPromotionId || null,
      driverAge: {
        age: searchCars.params.driverAge || null,
        betweenMinAndMax: true,
      },
      commercialAgreementCode:
        commercialAgreementCode ||
        profile?.commercialAgreementCode ||
        profile?.commercialAgreements?.[0] ||
        null,
      agencyGuid: agencyGuid || null,
    };
  }

  async componentDidMount() {
    const {
      i18n,
      t,
      history,
      fetchBookeableCars,
      fetchPlaces,
      fetchHolidays,
      fetchSchedules,
      fetchCategories,
      formOrientation,
      settings: { featureFlags, configurations },
      profile,
      listCurrencies,
      setWebCurrencyAction,
    } = this.props;
    const { searchQueryStrings: params, kmFilter, agencyGuid } = this.state;

    await fetchPlaces(i18n.language);
    await fetchHolidays();
    await fetchSchedules();
    if (formOrientation === 'luxury') await fetchCategories(i18n.language);

    const globalId = profile ? profile.globalId : agencyGuid ?? null;

    if (!this.state.isHome) {
      if (params != null && Object.keys(params).length > 0) {
        if (
          !params.dateFrom ||
          !params.hourFrom ||
          !params.pickUpId ||
          !params.pickUpEndpoint ||
          !params.dateTo ||
          !params.hourTo ||
          !params.dropOffId ||
          !params.dropOffEndpoint
        ) {
          const paramsOrDefault = {
            ...params,
            dateFrom: params.dateFrom || this.state.pickUp.date,
            hourFrom: params.hourFrom || this.state.pickUp.hour,
            pickUpId: params.pickUpId || this.state.pickUp.place.id,
            pickUpEndpoint: params.pickUpEndpoint || this.state.pickUp.place.rentlyEndpointName,
            dateTo: params.dateTo || this.state.dropOff.date,
            hourTo: params.hourTo || this.state.dropOff.hour,
            dropOffId: params.dropOffId || this.state.dropOff.place.id,
            dropOffEndpoint: params.dropOffEndpoint || this.state.dropOff.place.rentlyEndpointName,
          };

          const paramsString = queryString.stringify(paramsOrDefault);

          const link = `${tlink(
            '__Routes.search',
            t,
            i18n,
            null,
            configurations.langConfig,
          )}?${paramsString}`;
          return history.push(link);
        }

        //querystring has info
        let ilimitedKm = kmFilter.selected;
        const showFinalPrice = featureFlags.showFinalPrice ? featureFlags.showFinalPrice : false;
        params['onlyFullAvailability'] = featureFlags.onlyFullAvailability
          ? featureFlags.onlyFullAvailability
          : false;

        await fetchBookeableCars(
          params,
          i18n.language,
          ilimitedKm,
          showFinalPrice,
          globalId,
          listCurrencies,
          setWebCurrencyAction,
          featureFlags.isNewApi,
        );
      } else {
        this.submitSearch();
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.places || !props.schedules || !props.holidays) return null;

    if (state.pickUp.place === null && props.places && props.places.length) {
      const {
        searchQueryStrings: {
          pickUpId,
          pickUpEndpoint,
          dropOffId,
          dropOffEndpoint,
          categories,
          customPromotionId,
        },
        kmFilter,
        commercialAgreementCode,
        agencyGuid,
      } = state;

      const {
        t,
        searchCars,
        profile,
        schedules,
        settings: { configurations },
      } = props;
      const { minStayLength } = state;

      const places = getFilteredPlaces(props.places, configurations, schedules);
      const pickUpPlaces = getFilteredPickUpPlaces(places, t);
      const dropOffPlaces = getFilteredDropOffPlaces(places, t);
      let selectedPlace = pickUpId || props.placeId || searchCars.params.pickUpId || null;
      let selectedEndpoint =
        pickUpEndpoint || props.pickUpEndpoint || searchCars.params.pickUpEndpoint || null;

      let selectedDropPlace = props.placeId || dropOffId || searchCars.params.dropOffId || null;
      // pickUpEndpoint is not a typo. prop is receive through landing and we want same drop place by default
      let selectedDropEndpoint =
        props.pickUpEndpoint || dropOffEndpoint || searchCars.params.dropOffEndpoint || null;

      const disableDefaultBranchOffice = props?.settings?.featureFlags?.disableDefaultBranchOffice;

      const defaultPickUpPlaceValue =
        !state.isHome || !disableDefaultBranchOffice ? pickUpPlaces[0] : null;

      const pickUpPlace = selectedPlace
        ? pickUpPlaces.find(
            p => p.id === Number(selectedPlace) && p.rentlyEndpointName === selectedEndpoint,
          ) || defaultPickUpPlaceValue
        : defaultPickUpPlaceValue;

      const defaultDropOffPlaceValue = isADropOffPlace(pickUpPlace, t)
        ? pickUpPlace
        : !disableDefaultBranchOffice
        ? dropOffPlaces[0]
        : null;

      const dropOffPlace = selectedDropPlace
        ? places.find(
            p =>
              p.id === Number(selectedDropPlace) && p.rentlyEndpointName === selectedDropEndpoint,
          ) || defaultDropOffPlaceValue
        : defaultDropOffPlaceValue;

      const pickUpPlaceOption = transformPlaceToSelectOption(pickUpPlace, t);
      const dropOffPlaceOption = transformPlaceToSelectOption(dropOffPlace, t);

      const defaultDateFrom =
        state.pickUp.moment ||
        calculateStartDate(
          props.schedules,
          props.holidays,
          (pickUpPlace || pickUpPlaces[0]).branchOfficeId,
          state.pickUp.moment,
        );
      const defaultDateTo =
        state.dropOff.moment ||
        calculateEndDate(
          defaultDateFrom,
          minStayLength,
          props.schedules,
          props.holidays,
          (pickUpPlace || dropOffPlaces[0]).branchOfficeId,
        );

      const { dropOffHours, pickUpHours } = _calculatePickDropHours({
        pickUpDate: defaultDateFrom,
        dropOffDate: defaultDateTo,
        pickUpBranchOfficeId: pickUpPlace ? pickUpPlace.branchOfficeId : null,
        dropOffBranchOfficeId: dropOffPlace ? dropOffPlace.branchOfficeId : null,
        schedules: props.schedules,
        holidays: props.holidays,
      });

      const scheduleConf = getSchedule(
        props.schedules,
        (dropOffPlace || dropOffPlaces[0]).branchOfficeId,
      );
      const checkoutTime =
        scheduleConf != null &&
        scheduleConf.changeOfDayWithFixedSchedule == true &&
        scheduleConf.checkOutTime != null
          ? scheduleConf.checkOutTime.substring(0, 5)
          : null;

      const defaultKmFilter = kmFilter.selected;

      const filteredCategories = categories ? categories : props.categories || [];
      const cat = Array.isArray(filteredCategories)
        ? filteredCategories.map(x => parseInt(x))
        : !isNaN(parseInt(filteredCategories))
        ? [parseInt(filteredCategories)]
        : [];

      const params = {
        dateFrom: defaultDateFrom.format('DD-MM-YYYY'),
        hourFrom: state.pickUp.hour,
        pickUpId: pickUpPlace?.id || null,
        pickUpEndpoint: pickUpPlace?.rentlyEndpointName || null,
        dateTo: defaultDateTo.format('DD-MM-YYYY'),
        hourTo: state.dropOff.hour,
        dropOffId: dropOffPlace?.id || null,
        dropOffEndpoint: dropOffPlace?.rentlyEndpointName || null,
        categories: cat || [],
        ilimitedKm: defaultKmFilter,
        customPromotionId: customPromotionId || searchCars.params.customPromotionId,
        driverAge: state.driverAge.age || null,
        commercialAgreementCode:
          commercialAgreementCode ||
          profile?.commercialAgreementCode ||
          profile?.commercialAgreements?.[0] ||
          null,
        agencyGuid: agencyGuid || null,
      };
      props.setSearchCarParams(params);
      const defaultDropOffHr =
        checkoutTime ||
        state.dropOff.hour ||
        (dropOffHours.includes(pickUpHours[0]) ? pickUpHours[0] : dropOffHours[0]);

      const defaultPickUpHr = state.pickUp.hour || pickUpHours[0];

      return {
        ...state,
        pickUp: {
          ...state.pickUp,
          place: pickUpPlaceOption,
          hours: pickUpHours,
          hour: state.pickUp.hour || pickUpHours[0],
          date: defaultDateFrom.format('DD-MM-YYYY'),
          moment: parseHourToMoment(defaultPickUpHr, defaultDateFrom),
        },
        dropOff: {
          ...state.dropOff,
          place: dropOffPlaceOption,
          hours: dropOffHours,
          hour: defaultDropOffHr,
          date: defaultDateTo.format('DD-MM-YYYY'),
          moment: parseHourToMoment(defaultDropOffHr, defaultDateTo),
          checkoutTime: checkoutTime,
        },
        showDifferentDropPlace:
          pickUpPlaceOption.id !== dropOffPlaceOption.id ||
          pickUpPlaceOption.rentlyEndpointName !== dropOffPlaceOption.rentlyEndpointName,
        kmFilter: {
          selected: defaultKmFilter,
        },
        driverAge: {
          age: state.driverAge.age || null,
          betweenMinAndMax: !state.driverAge.age,
        },
      };
    }

    return null;
  }

  onCommercialAgreementChange = code => {
    this.setState(prevState => ({
      ...prevState,
      commercialAgreementCode: code,
    }));
  };

  handleDriverAgeChange = (betweenMinAndMax, age = null) => {
    this.setState(() => ({
      ...this.state,
      driverAge: { age, betweenMinAndMax },
    }));
  };

  getDropOffPlaceAvailable = (rentlyEndpointName, tentativePlace) => {
    const allowForDeliveryPlaces = this.filteredDropPlaces();
    const filteredPlaces = allowForDeliveryPlaces.filter(
      p => p.rentlyEndpointName === rentlyEndpointName,
    );

    const placeIsPresent = filteredPlaces.find(p => p.id === tentativePlace.id);
    return placeIsPresent ? tentativePlace : transformPlaceToSelectOption(filteredPlaces[0]);
  };

  pickUpSelect = selectedOption => {
    if (!selectedOption) return;
    const { schedules, holidays, t } = this.props;
    const { showDifferentDropPlace, dropOff, minStayLength } = this.state;

    const allowForDeliveryPlaces = this.filteredDropPlaces();

    const dropOffBranchOffice =
      showDifferentDropPlace && dropOff.place
        ? dropOff.place
        : isADropOffPlace(selectedOption, t)
        ? selectedOption
        : allowForDeliveryPlaces[0];

    const branchOfficeIdForEnd = dropOffBranchOffice?.branchOfficeId;

    const newStartDate = calculateStartDate(
      schedules,
      holidays,
      selectedOption.branchOfficeId,
      this.state.pickUp.moment,
    );
    const newEndDate = calculateEndDate(
      newStartDate,
      minStayLength,
      schedules,
      holidays,
      branchOfficeIdForEnd,
      this.state.dropOff.moment,
    );

    const { pickUpHours } = this.calculatePickDropHours({
      pickUpDate: newStartDate,
      pickUpBranchOfficeId: selectedOption.branchOfficeId || this.props.places[0].branchOfficeId,
    });

    const { dropOffHours } = showDifferentDropPlace
      ? this.calculatePickDropHours({
          dropOffBranchOfficeId: dropOff.place.branchOfficeId,
          dropOffDate: newEndDate,
        })
      : this.calculatePickDropHours({
          dropOffBranchOfficeId: selectedOption.branchOfficeId,
          dropOffDate: newEndDate,
        });

    const scheduleConf = getSchedule(schedules, branchOfficeIdForEnd);
    const checkoutTime =
      scheduleConf != null &&
      scheduleConf.changeOfDayWithFixedSchedule == true &&
      scheduleConf.checkOutTime != null
        ? scheduleConf.checkOutTime.substring(0, 5)
        : null;

    this.setState(prevState => {
      const pickUp = {
        ...prevState.pickUp,
        place: selectedOption,
        hours: pickUpHours || pickUpHours[0],
        date: newStartDate,
        moment: newStartDate,
      };

      const dropOff = {
        ...prevState.dropOff,
        place: showDifferentDropPlace
          ? this.getDropOffPlaceAvailable(
              dropOffBranchOffice.rentlyEndpointName,
              prevState.dropOff.place,
            )
          : dropOffBranchOffice,
        date: newEndDate,
        moment: newEndDate,
        hours: dropOffHours,
        checkoutTime: checkoutTime,
        hour: checkoutTime != null ? checkoutTime : prevState.dropOff.hour,
      };

      return {
        ...prevState,
        pickUp,
        dropOff,
      };
    });
  };

  // when dropOff place was removed. we set it equal to pickup place.
  dropOffSelect = selectedOption => {
    if (this.state.pickUp.place === null) {
      this.setState({
        pickUp: { ...this.state.pickUp, place: selectedOption },
      });
    }
    const { pickUp, minStayLength } = this.state;
    const { schedules, holidays } = this.props;
    const place = selectedOption || pickUp.place || {};

    const newEndDate = calculateEndDate(
      pickUp.moment,
      minStayLength,
      schedules,
      holidays,
      place.branchOfficeId,
      this.state.dropOff.moment,
    );
    const { dropOffHours } = this.calculatePickDropHours({
      dropOffBranchOfficeId: place.branchOfficeId,
      dropOffDate: newEndDate,
    });

    this.setState(prevState => ({
      ...prevState,
      dropOff: {
        ...prevState.dropOff,
        place: place,
        hours: dropOffHours,
        date: newEndDate,
        moment: newEndDate,
      },
    }));
  };

  toggleDropInput = () => {
    this.setState(prevState => ({
      ...prevState,
      showDifferentDropPlace: !this.state.showDifferentDropPlace,
    }));
  };

  handleDateSelect = (startDate, endDate) => {
    if (!startDate) return false;
    const pickUpDate = startDate.format('DD-MM-YYYY');
    let dropOffDate = '';
    let cleanEndDate = endDate ? endDate.clone() : null;
    const maxDate = this.getMaximumStay(startDate);

    if (cleanEndDate) {
      if (endDate.isAfter(maxDate)) {
        dropOffDate = maxDate.format('DD-MM-YYYY');
        cleanEndDate = maxDate;
      } else {
        dropOffDate = endDate.format('DD-MM-YYYY');
      }
    }
    const { pickUpHours, dropOffHours } = this.calculatePickDropHours({
      pickUpDate: startDate,
      dropOffDate: cleanEndDate,
    });
    this.setState(prevState => {
      const { dropOff, pickUp } = prevState;

      const prevDropOffMaxDay =
        dropOff.moment && dropOff.moment.isAfter(maxDate) ? maxDate : dropOff.moment;

      return {
        ...prevState,
        pickUp: {
          ...pickUp,
          date: pickUpDate,
          moment: startDate,
          hours: pickUpHours,
          hour: pickUpHours.find(v => v === pickUp.hour) || pickUpHours[0],
        },
        dropOff: {
          ...dropOff,
          date: dropOffDate,
          moment: cleanEndDate || prevDropOffMaxDay,
          hours: dropOffHours,
          hour: dropOffHours.find(v => v === dropOff.hour) || dropOffHours[0],
        },
        maximumStay: maxDate,
      };
    });
  };

  calculatePickDropHours = ({
    pickUpDate,
    dropOffDate,
    pickUpBranchOfficeId,
    dropOffBranchOfficeId,
  } = {}) => {
    const { schedules, holidays } = this.props;
    const {
      dropOff: { moment: oldDropOffDate, place: oldDropOffBranchOffice },
      pickUp: { moment: oldPickUpDate, place: oldPickUpBranchOffice },
    } = this.state;

    return _calculatePickDropHours({
      pickUpDate: pickUpDate || oldPickUpDate,
      dropOffDate: dropOffDate || oldDropOffDate,
      pickUpBranchOfficeId:
        pickUpBranchOfficeId || (oldPickUpBranchOffice && oldPickUpBranchOffice.branchOfficeId),
      dropOffBranchOfficeId:
        dropOffBranchOfficeId || (oldDropOffBranchOffice && oldDropOffBranchOffice.branchOfficeId),
      schedules,
      holidays,
    });
  };

  handleDateFocus = focus => {
    this.setState(prevState => ({
      ...prevState,
      rangeDatePicker: { ...prevState.rangeDatePicker, focus },
    }));
  };

  handleKmFilter = value => {
    const selected = (this.state.kmFilter.selected = value);

    this.setState(prevState => ({
      kmFilter: { ...prevState.kmFilter, selected },
    }));
  };

  pickUpHourSelection = hour => {
    const { pickUp, dropOff } = this.state;
    const newDropOffHour = dropOff.hours && dropOff.hours.includes(hour) ? hour : dropOff.hours[0];

    this.setState(prevState => ({
      ...prevState,
      pickUp: {
        ...prevState.pickUp,
        hour,
        moment: parseHourToMoment(hour, pickUp.moment),
      },
      dropOff: {
        ...prevState.dropOff,
        hour: newDropOffHour,
        moment: parseHourToMoment(newDropOffHour, dropOff.moment),
      },
    }));
  };

  dropOffHourSelection = hour => {
    const { dropOff } = this.state;

    this.setState(prevState => ({
      ...prevState,
      dropOff: {
        ...prevState.dropOff,
        hour,
        moment: parseHourToMoment(hour, dropOff.moment),
      },
    }));
  };

  onPromotionCodeChange = value => {
    this.setState(prevState => ({
      ...prevState,
      promotionCode: value,
      showInvalidPromotionMessage: false,
    }));
  };

  isInvalidDriverAge = () => {
    const { driverAge } = this.state;
    const { bookingsConfiguration, featureFlags } = this.props;

    const driverAgeAllowedMin =
      bookingsConfiguration && bookingsConfiguration.driverAgeAllowedMin
        ? bookingsConfiguration.driverAgeAllowedMin
        : 0;

    const driverAgeAllowedMax =
      bookingsConfiguration && bookingsConfiguration.driverAgeAllowedMax
        ? bookingsConfiguration.driverAgeAllowedMax
        : 120;

    return (
      featureFlags.showDriverAge &&
      !driverAge.betweenMinAndMax &&
      (!driverAge.age || driverAge.age < driverAgeAllowedMin || driverAge.age > driverAgeAllowedMax)
    );
  };

  disableSubmit = () => {
    const { pickUp, dropOff, minStayLength, loading } = this.state;

    const invalidDriverAge = this.isInvalidDriverAge();
    const isLessThanMinLength = () => {
      if (pickUp.moment && dropOff.moment) {
        if (minStayLength > 0) {
          return dropOff.moment.diff(pickUp.moment, 'days') < minStayLength;
        }
        return dropOff.moment.diff(pickUp.moment, 'minutes') <= 0;
      }
    };

    return (
      !(pickUp.place && pickUp.date && pickUp.hour && dropOff.date && dropOff.hour) ||
      isLessThanMinLength() ||
      invalidDriverAge ||
      loading
    );
  };

  isOutsideRange = day => {
    const {
      pickUp,
      rangeDatePicker: { focus },
    } = this.state;

    if (focus === 'startDate') return false;

    const maxStay = this.getMaximumStay(pickUp.moment);
    const today = moment().startOf('day');
    const isBeforeToday = day.isBefore(today);
    const isAfterMaximumStay = maxStay && day.isAfter(maxStay);
    return isBeforeToday || isAfterMaximumStay;
  };

  isDayBlocked = (day, focusedInput) => {
    const {
      holidays,
      schedules,
      settings: { configurations },
    } = this.props;
    const { pickUp, dropOff } = this.state;
    const placeId = focusedInput === 'startDate' ? pickUp.place.id : dropOff.place.id;

    const dateType = focusedInput === 'startDate' ? 'start' : 'end';

    const places = getFilteredPlaces(this.props.places, configurations, schedules);

    const selectedPlace = places.find(p => p.id === placeId) || places[0];
    let scheduleConf = getSchedule(schedules, selectedPlace.branchOfficeId);

    return !isDayAvailable(day, scheduleConf, holidays, dateType, false);
  };

  isDayBlockedByHoliday = (day, holidays, branchOfficeId, scheduledDays) => {
    const holiday = holidays.find(h => h.date === day.format('YYYY-MM-DD'));
    if (!holiday) return false;

    if (holiday.ids.length === 0 || holiday.ids.includes(branchOfficeId)) {
      return scheduledDays ? scheduledDays['holiday'].length === 0 : true;
    } else {
      return false;
    }
  };

  getMaximumStay = pickUpDay => {
    const { configurations, bookingsConfiguration } = this.props;
    const maxStay = configurations?.maxBookingLength || bookingsConfiguration?.maxDays;

    if (maxStay && pickUpDay) {
      let maximumStay = moment(pickUpDay);
      return maximumStay.add(maxStay, 'd');
    }
    return null;
  };

  async submitLuxurySearch(userInfo) {
    const { i18n, listCategories, book } = this.props;
    const {
      pickUp,
      dropOff,
      kmFilter,
      promotionCode,
      commercialAgreementCode,
      agencyGuid,
    } = this.state;

    this.setState({ loading: true, errorMessage: '' });

    const principalModel =
      userInfo.category !== 'all'
        ? listCategories.find(c => c.id === parseFloat(userInfo.category)).principalModelId
        : listCategories[0].principalModelId;

    const booking = {
      car: {
        model: { id: principalModel },
      },
      ilimitedKm: kmFilter.selected,
      additionals: [],
      promotion: null,
      fromDate: pickUp.moment.format('MM-DD-YYYY HH:mm'),
      toDate: dropOff.moment.format('MM-DD-YYYY HH:mm'),
    };

    const bookingParams = {
      pickUpEndpoint: pickUp.place.rentlyEndpointName,
      pickUpId: pickUp.place.id,
      dropOffEndpoint: dropOff.place.rentlyEndpointName,
      dropOffId: dropOff.place.id,
      promotionCode: promotionCode || null,
      onlinePayment: false,
      // TODO: Modificar este texto en base a lo necesario en backend
      extra: userInfo.category !== 'all' ? userInfo.notes : 'ALL CATEGORIES',
      commercialAgreementCode: commercialAgreementCode || null,
      agencyGuid: agencyGuid || null,
    };

    book(booking, userInfo, bookingParams, true, i18n.language);
    this.submitSearch();
  }

  async submitSearch() {
    const {
      setSearchCarParams,
      history,
      t,
      i18n,
      categories,
      onSubmit,
      settings,
      isWidget,
      submitOnNewTab,
      submitToExternalUrl,
    } = this.props;

    const {
      pickUp,
      dropOff,
      kmFilter,
      promotionCode,
      driverAge,
      commercialAgreementCode,
      agencyGuid,
    } = this.state;
    const { featureFlags } = settings;

    let customPromotionId = null;
    if (promotionCode) {
      customPromotionId = (
        await getValidPromotionCode(
          pickUp.place.rentlyEndpointName,
          encodeURIComponent(promotionCode),
        )
      ).data;
      if (customPromotionId === -1) {
        this.setState({ showInvalidPromotionMessage: true });
        return;
      }
    }

    let promos = [];
    if (
      settings?.paymentConfiguration?.promotions?.[pickUp.place.rentlyEndpointName]?.onlinePromotion
    )
      promos.push(
        settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName].onlinePromotion,
      );
    if (
      settings?.paymentConfiguration?.promotions?.[pickUp.place.rentlyEndpointName]
        ?.counterPromotion
    )
      promos.push(
        settings.paymentConfiguration.promotions[pickUp.place.rentlyEndpointName].counterPromotion,
      );

    const params = {
      dateFrom: pickUp.moment.format('DD-MM-YYYY'),
      hourFrom: pickUp.hour,
      pickUpId: pickUp.place.id,
      pickUpEndpoint: pickUp.place.rentlyEndpointName,
      dateTo: dropOff.moment.format('DD-MM-YYYY'),
      hourTo: dropOff.hour,
      dropOffId: dropOff.place.id,
      dropOffEndpoint: dropOff.place.rentlyEndpointName,
      categories: categories || [],
      ilimitedKm: kmFilter.selected,
      onlyFullAvailability: featureFlags.onlyFullAvailability
        ? featureFlags.onlyFullAvailability
        : false,
      promotionCode: promotionCode || null,
      promotions: promotionCode ? [...promos, customPromotionId] : promos,
      customPromotionId: customPromotionId || null,
      driverAge: driverAge.age ? parseFloat(driverAge.age) : null,
      commercialAgreementCode: commercialAgreementCode || null,
      agencyGuid: agencyGuid || null,
    };

    if (!this.disableSubmit) return false;

    setSearchCarParams(params);
    if (onSubmit) onSubmit();

    // TODO: paramtrize this route (getting it from configuration)
    const paramsString = queryString.stringify(params);
    window.postMessage({ event: 'search', payload: paramsString }, '*');

    if (isWidget === true) {
      const urlSite = submitToExternalUrl ? submitToExternalUrl : window.location.origin;
      const route = `${tlink(
        '__Routes.search',
        t,
        i18n,
        null,
        settings.configurations.langConfig,
      )}?${paramsString}`;
      const finalUrl = submitToExternalUrl ? `${urlSite}?${paramsString}` : urlSite + route;

      if (submitOnNewTab === true) {
        window.open(finalUrl, '_blank');
        return;
      }

      window.parent.location = finalUrl;
      return;
    }

    const link = `${tlink(
      '__Routes.search',
      t,
      i18n,
      null,
      settings.configurations.langConfig,
    )}?${paramsString}`;
    return history.push(link);
  }

  filteredDropPlaces = () => {
    const { pickUp } = this.state;
    const {
      settings: { configurations },
      schedules,
    } = this.props;

    const places = getFilteredPlaces(this.props.places, configurations, schedules);

    if (!pickUp.place) return places;

    return this.getAvailableDropOffPlaces(pickUp, places);
  };

  getAvailableDropOffPlaces = (pickUp, places) => {
    const { t } = this.props;
    if (!places || !pickUp.place) return [];

    if (!pickUp.place.availableReturnPlaces || pickUp.place.availableReturnPlaces.length === 0) {
      return [pickUp.place];
    }

    let possiblePlaces = [];
    possiblePlaces.push(pickUp.place);

    pickUp.place.availableReturnPlaces.forEach(placeId => {
      const place = places.find(p => p.id === placeId);
      if (place && !possiblePlaces.some(i => i.id === place.id)) {
        possiblePlaces.push(place);
      }
    });
    // Remove only delivery places
    possiblePlaces = getFilteredDropOffPlaces(possiblePlaces, t);

    return possiblePlaces;
  };

  parseDate = stringDate => moment(stringDate, 'DD-MM-YYYY');

  render() {
    const {
      searchText,
      className,
      featureFlags,
      configurations,
      promotionCodeText,
      transparent,
      checkoutText,
      showCity,
      showIATA,
      formOrientation,
      bookingsConfiguration,
      resultsPage,
      profile,
      schedules,
      places,
      settings,
      useBackendSort,
      customText,
      t,
    } = this.props;

    const {
      showDifferentDropPlace,
      rangeDatePicker: { orientation, focus, withFullScreenPortal },
      pickUp,
      dropOff,
      promotionCode,
      showInvalidPromotionMessage,
      kmFilter,
      driverAge,
      isHome,
      commercialAgreementCode,
      minStayLength,
      loading,
      errorMessage,
    } = this.state;

    const driverMinAge =
      bookingsConfiguration && bookingsConfiguration.driverAgeWithoutCautionMin
        ? bookingsConfiguration.driverAgeWithoutCautionMin
        : 20;
    const driverMaxAge =
      bookingsConfiguration && bookingsConfiguration.driverAgeWithoutCautionMax
        ? bookingsConfiguration.driverAgeWithoutCautionMax
        : 65;

    const invalidDriverAge = this.isInvalidDriverAge();

    const useDriverAgeWithoutCaution =
      bookingsConfiguration && bookingsConfiguration.useDriverAgeWithoutCaution
        ? bookingsConfiguration.useDriverAgeWithoutCaution
        : false;
    const driverAgeAllowedMin =
      bookingsConfiguration && bookingsConfiguration.driverAgeAllowedMin
        ? bookingsConfiguration.driverAgeAllowedMin
        : 20;
    const driverAgeAllowedMax =
      bookingsConfiguration && bookingsConfiguration.driverAgeAllowedMax
        ? bookingsConfiguration.driverAgeAllowedMax
        : 65;

    const filteredPlaces = getFilteredPlaces(places, settings.configurations, schedules);

    const searchFormProps = {
      searchText,
      className,
      featureFlags,
      configurations,
      promotionCodeText,
      transparent,
      checkoutText,
      showCity,
      showIATA,
      showDifferentDropPlace,
      orientation,
      focus,
      withFullScreenPortal,
      pickUp,
      dropOff,
      promotionCode,
      showInvalidPromotionMessage,
      kmFilter,
      invalidDriverAge,
      driverMinAge,
      driverMaxAge,
      driverAge,
      driverAgeWarning: configurations.driverAgeWarning,
      resultsPage,
      commercialAgreements: profile && profile.commercialAgreements,
      isHome,
      commercialAgreementCode,
      isCompany: isCompanyUser(profile),
      useDriverAgeWithoutCaution,
      driverAgeAllowedMin,
      driverAgeAllowedMax,
      useBackendSort,
      customText,
      pickUpPlaces: getFilteredPickUpPlaces(filteredPlaces, t),
      minStayLength,
      loading,
      errorMessage,
    };

    if (formOrientation === 'horizontal') {
      return (
        <ModernSearchForm
          {...searchFormProps}
          dropOffSelect={this.dropOffSelect}
          pickUpSelect={this.pickUpSelect}
          toggleDropInput={this.toggleDropInput}
          handleDateSelect={this.handleDateSelect}
          handleDateFocus={this.handleDateFocus}
          isDayBlocked={this.isDayBlocked}
          pickUpHourSelection={this.pickUpHourSelection}
          dropOffHourSelection={this.dropOffHourSelection}
          onPromotionCodeChange={this.onPromotionCodeChange}
          handleKmFilter={this.handleKmFilter}
          submitSearch={this.submitSearch}
          disableSubmit={this.disableSubmit}
          filteredDropPlaces={this.filteredDropPlaces}
          handleDriverAgeChange={this.handleDriverAgeChange}
          onCommercialAgreementChange={this.onCommercialAgreementChange}
          customText={customText}
          distanceUnit={bookingsConfiguration?.distanceUnit}
          isOutsideRange={this.isOutsideRange}
        />
      );
    }

    if (formOrientation === 'luxury') {
      return (
        <LuxurySearchForm
          {...searchFormProps}
          categories={this.props.listCategories}
          dropOffSelect={this.dropOffSelect}
          pickUpSelect={this.pickUpSelect}
          toggleDropInput={this.toggleDropInput}
          handleDateSelect={this.handleDateSelect}
          handleDateFocus={this.handleDateFocus}
          isDayBlocked={this.isDayBlocked}
          pickUpHourSelection={this.pickUpHourSelection}
          dropOffHourSelection={this.dropOffHourSelection}
          onPromotionCodeChange={this.onPromotionCodeChange}
          handleKmFilter={this.handleKmFilter}
          submitSearch={this.submitLuxurySearch}
          disableSubmit={this.disableSubmit}
          filteredDropPlaces={this.filteredDropPlaces}
          handleDriverAgeChange={this.handleDriverAgeChange}
          onCommercialAgreementChange={this.onCommercialAgreementChange}
          customText={customText}
          distanceUnit={bookingsConfiguration?.distanceUnit}
          isOutsideRange={this.isOutsideRange}
        />
      );
    }

    return (
      <DefaultSearchForm
        {...searchFormProps}
        dropOffSelect={this.dropOffSelect}
        pickUpSelect={this.pickUpSelect}
        toggleDropInput={this.toggleDropInput}
        handleDateSelect={this.handleDateSelect}
        handleDateFocus={this.handleDateFocus}
        isDayBlocked={this.isDayBlocked}
        pickUpHourSelection={this.pickUpHourSelection}
        dropOffHourSelection={this.dropOffHourSelection}
        onPromotionCodeChange={this.onPromotionCodeChange}
        handleKmFilter={this.handleKmFilter}
        submitSearch={this.submitSearch}
        disableSubmit={this.disableSubmit}
        filteredDropPlaces={this.filteredDropPlaces}
        handleDriverAgeChange={this.handleDriverAgeChange}
        onCommercialAgreementChange={this.onCommercialAgreementChange}
        customText={customText}
        distanceUnit={bookingsConfiguration?.distanceUnit}
        isOutsideRange={this.isOutsideRange}
      />
    );
  }
}

const mapStateToProps = ({ user, siteConfiguration, bookings, global }) => ({
  profile: user.profile,
  settings: siteConfiguration.settings,
  bookingsConfiguration: bookings.configuration,
  listCurrencies: global.listCurrencies,
});

export default connect(mapStateToProps, { setWebCurrencyAction })(
  withTheme(withTranslation()(SearchForm)),
);
