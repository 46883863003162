import React from 'react';
import { orderBy, groupBy } from 'lodash';
import { connect } from 'react-redux';
import FormFile from 'react-bootstrap/FormFile';
import { props, defaultProps } from './CheckoutAdditionals.props';
import { StyledAdditionals } from './CheckoutAdditionals.styled';
import {
  Title,
  Btn,
  TextInput,
  Paragraph,
  CustomLoading,
  LoginForm,
  TextAreaInput,
  DateInput,
} from 'components/';
import AdditionalRow from './AdditionalRow';
import { withTranslation } from 'react-i18next';
import { tt, tlink } from '../../../utils/translationHelper';
import { debounce } from '../../../utils/debounceHelper';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { CheckboxInput, PaymentMethods, SelectInput } from '/components';
import { CheckoutMobileDetails, CheckoutFilter } from '/sections';
import { history } from '../../../store';
import { book } from '../../../actions/bookings.actions';
import {
  fetchDocumentTypes,
  fetchCountries,
  fetchCities,
  fetchFiscalConditions,
} from '../../../actions/global.actions';
import { handleLogOut, handleLogIn } from 'actions';
import {
  is_gtag_enabled,
  gtag_additional_impresions,
  gtag_checkout_process,
  gtag_purchase,
  gtag_manage_shopping_cart,
} from '../../../utils/googleTagsHelper';

import {
  cleanRUT,
  getDocumentSample,
  isValidDocument,
  transformDocTypesToSelectOptions,
  transformFiscalConditionsToSelectOptions,
} from '../../../utils/documentHelper';
import { MODERN_THEME } from 'styled/constants';
import { StyledAdditionalsModern } from './CheckoutAdditionals.style.modern';
import {
  convertToBase64,
  getDocumentMask,
  getDocumentTypeName,
  isCompanyUser,
  transformCountriesToSelectOptions,
} from 'utils/dataHelper';
import { Modal } from 'react-bootstrap';
import { register } from 'services/userService';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import AdditionalCard from './AdditionalCard';
import 'react-phone-number-input/style.css';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

class CheckoutAdditionals extends React.Component {
  constructor(props) {
    const {
      t,
      withBillingInformation,
      hideZipCode,
      showIsCompany,
      profile,
      settings: {
        featureFlags,
        configurations: { fiscalConditionValidation },
        myAccountConfiguration,
      },
      bookingsConfiguration,
      booking,
    } = props;
    super(props);

    this.state = {
      additionals: [],
      isInsuranceSelected: false,
      documentTypes: [],
      fiscalConditions: [],
      customDelivery: booking?.deliveryPlace?.canAddCustomAddress ? true : false,
      customReturn: booking?.returnPlace?.canAddCustomAddress ? true : false,
      userInfo: {
        isCustomAddress: false,
        tcAccept: featureFlags?.hideTermsCheckbox ? true : false,
        paymentMethod: false,
        paymentMethodObj: null,
        showPaymentMethod:
          this.props.settings.paymentConfiguration != null &&
          this.props.onlinePayment &&
          this.props.settings.paymentConfiguration.paymentMethods != null,
        showBillingFields: withBillingInformation !== undefined ? withBillingInformation : false,
        tenantDocumentTypes: [],
        tenantFiscalConditions: [],
        fiscalConditionId: null,
        hideFiscalCondition: featureFlags?.hideFiscalCondition || false,
        registerMe: false,
        hideAddressField: this.props.hideAddressField || false,
        hideCountryField: this.props.hideCountryField || false,
        hideDocumentField: this.props.hideDocumentField || false,
        hideFlightNumber: this.props.hideFlightNumber || false,
        hidePhoneField: this.props.hidePhoneField || false,
        airlineField: this.props.airlineField || false,
        hideZipCode: hideZipCode !== undefined ? hideZipCode : false,
        showIsCompany: showIsCompany !== undefined ? showIsCompany : false,
        isCompany: false,
        loggedAsCompany: isCompanyUser(profile),
        withPurchaseOrder: null,
        withPurchaseOrderFile: null,
        companyFiles: [],
        enableLicenseData:
          featureFlags.enableLicenseData != undefined ? featureFlags.enableLicenseData : false,
        enableCityInput:
          featureFlags.enableCityInput != undefined ? featureFlags.enableCityInput : false,
        enableStateInput:
          featureFlags.enableStateInput != undefined ? featureFlags.enableStateInput : false,
        showBirthDateField:
          featureFlags.showBirthDateField != undefined ? featureFlags.showBirthDateField : false,
        birthDate: null,
        forceRutWhenChile:
          featureFlags.forceRutWhenChile != undefined ? featureFlags.forceRutWhenChile : false,
        showCompanyExtraDocumentsFields:
          myAccountConfiguration?.showCompanyExtraDocumentsFields != undefined
            ? myAccountConfiguration.showCompanyExtraDocumentsFields
            : false,
        hidePurchaseOrderFields: myAccountConfiguration?.hidePurchaseOrderFields || false,
        requireNotes: myAccountConfiguration?.requireNotes || false,
        showAddressAllFields:
          featureFlags.showAddressAllFields != undefined
            ? featureFlags.showAddressAllFields
            : false,
      },
      formErrors: {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        birthDay: '',
        city: '',
        cityInput: '',
        stateInput: '',
        phone: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        id: '',
        fiscalConditionId: null,
        typeId: '',
        tcAccept: '',
        paymentMethod: '',
        flightNumber: '',
        password: '',
        repeatPassword: '',
        isCompany: '',
        driverFirstName: '',
        driverLastName: '',
        driverEmail: '',
        driverCountry: '',
        driverCity: '',
        driverPhone: '',
        driverAddress: '',
        driverAddressNumber: '',
        driverAddressDepartment: '',
        driverZipCode: '',
        driverId: '',
        driverTypeId: '',
        driverLicenseNumber: '',
        driverLicenseCountry: '',
        driverLicenseExpiration: '',
        purchaseOrder: '',
        companyFiles: '',
        notes: '',
        withPurchaseOrder: '',
        withPurchaseOrderFile: '',
        customDeliveryAddress: '',
        customDeliveryCity: '',
        customReturnAddress: '',
        customReturnCity: '',
      },
      formLoading: false,
      quotationLoading: false,
      popUpErrorShow: false,
      isCheckoutFilterVisible: true,
      modalState: false,
      sampleDocFormat: '',
      docInputFocus: false,
      sampleDriverDocFormat: '',
      docDriverInputFocus: false,
    };

    yup.addMethod(yup.string, 'isValidDocument', function(documentId) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongDocumentFormat'),
        function(documentId) {
          const { tenantDocumentTypes, typeId } = this.parent;
          return isValidDocument(tenantDocumentTypes, typeId, documentId);
        },
      );
    });

    yup.addMethod(yup.string, 'isValidDriverDocument', function(documentId) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongDocumentFormat'),
        function(documentId) {
          const { tenantDocumentTypes, driverTypeId } = this.parent;
          return isValidDocument(tenantDocumentTypes, driverTypeId, documentId);
        },
      );
    });

    yup.addMethod(yup.string, 'hasValidDocumentType', function(fiscalCondition) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongFiscalCondition'),
        function(fiscalCondition) {
          const taxPayerValidation = (fiscalConditionValidation || [])?.find(
            i => `${i.id}` === fiscalCondition,
          );

          if (!taxPayerValidation?.validDocumentIds) return true;
          else {
            const validIds = taxPayerValidation?.validDocumentIds;
            if (validIds.includes(this.parent.typeId)) return true;
            return false;
          }
        },
      );
    });

    yup.addMethod(yup.string, 'isValidPassword', function(password) {
      return this.test('test-password', t('passwordError'), function(password) {
        return password.length > 5;
      });
    });

    yup.addMethod(yup.string, 'isValidRepeatPassword', function(repeatPassword) {
      return this.test('test-password', t('passwordMatch'), function(repeatPassword) {
        const { password } = this.parent;
        return password === repeatPassword;
      });
    });

    yup.addMethod(yup.string, 'isValidCity', function(city) {
      return this.test('test-city', t('inputIsRequired', { field: t('city') }), function(city) {
        const { country } = this.parent;

        if (country !== 'Chile' || (country === 'Chile' && city)) return true;
        return false;
      });
    });

    yup.addMethod(yup.mixed, 'birthDay', function(date) {
      const driverAgeAllowedMin =
        bookingsConfiguration && bookingsConfiguration.driverAgeAllowedMin
          ? bookingsConfiguration.driverAgeAllowedMin
          : 21;

      const driverAgeAllowedMax =
        bookingsConfiguration && bookingsConfiguration.driverAgeAllowedMax
          ? bookingsConfiguration.driverAgeAllowedMax
          : 85;
      return this.test(
        'birthDay',
        t('driverAgeBetween', {
          min: driverAgeAllowedMin,
          max: driverAgeAllowedMax,
        }),
        function(date) {
          const isAgeAllowed = date =>
            moment().diff(date, 'years') >= driverAgeAllowedMin &&
            moment().diff(date, 'years') <= driverAgeAllowedMax;
          return isAgeAllowed(date);
        },
      );
    });

    yup.addMethod(yup.mixed, 'driverLicenseExpiration', function(toDate, licenseValidDays) {
      return this.test(
        'driverLicenseExpiration',
        t('driverLicenseExpirationMessage', { licenseValidDays }),
        function(driverLicenseExpiration) {
          if (!driverLicenseExpiration) {
            return true;
          }

          const toDateMoment = moment(toDate, 'DD-MM-YYYY');
          const driverLicenseExpirationMoment = moment(driverLicenseExpiration);
          const toDatePlusDaysMoment = toDateMoment.add(licenseValidDays, 'days');

          const isValid = toDatePlusDaysMoment.isSameOrBefore(driverLicenseExpirationMoment);

          return isValid;
        },
      );
    });

    yup.addMethod(yup.string, 'isValidDriverCity', function(city) {
      return this.test('test-city', t('inputIsRequired', { field: t('city') }), function(city) {
        const { driverCountry } = this.parent;

        if (driverCountry !== 'Chile' || (driverCountry === 'Chile' && city)) return true;
        return false;
      });
    });

    yup.addMethod(yup.array, 'areCompanyFilesValid', function(companyFiles) {
      return this.test('company-files', t('companyFilesError'), function(companyFiles) {
        if (companyFiles.length === 0 || companyFiles.some(f => !f.name || !f.file)) {
          return false;
        }
        return true;
      });
    });

    yup.addMethod(yup.boolean, 'isWithPurchaseOrderSelected', function() {
      return this.test('with-purchase-order', t('withPurchaseOrderError'), function(
        withPurchaseOrder,
      ) {
        if (withPurchaseOrder === null) return false;
        return true;
      });
    });

    yup.addMethod(yup.boolean, 'isWithPurchaseOrderFileUndefined', function() {
      return this.test('with-purchase-order-file', t('withPurchaseOrderError'), function(
        withPurchaseOrderFile,
      ) {
        if (withPurchaseOrderFile === null) return false;
        return true;
      });
    });

    this.userInfoFormSchema = yup.object().shape({
      firstName: yup.string().required(t('inputIsRequired', { field: t('name') })),
      lastName: yup.string().required(t('inputIsRequired', { field: t('lastname') })),
      email: yup
        .string()
        .email(t('validEmail'))
        .required(t('inputIsRequired', { field: t('mail') })),
      phone: yup.string().when('hidePhoneField', {
        is: hidePhoneField => !hidePhoneField,
        then: yup.string().required(t('inputIsRequired', { field: t('phone') })),
        otherwise: yup.string(),
      }),
      flightNumber: yup.string().when('airlineField', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('airline') })),
        otherwise: yup.string(),
      }),
      address: yup.string().when('hideAddressField', {
        is: hideAddressField => !hideAddressField,
        then: yup.string().required(t('inputIsRequired', { field: t('address') })),
        otherwise: yup.string(),
      }),
      customDeliveryAddress: yup.string().when('isCustomDeliveryCity', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('customDeliveryAddress') })),
        otherwise: yup.string(),
      }),
      customDeliveryCity: yup.string().when('isCustomDeliveryAddress', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('customDeliveryCity') })),
        otherwise: yup.string(),
      }),
      customReturnAddress: yup.string().when('isCustomReturnCity', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('customReturnAddress') })),
        otherwise: yup.string(),
      }),
      customReturnCity: yup.string().when('isCustomReturnAddress', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('customReturnCity') })),
        otherwise: yup.string(),
      }),
      id: yup.string().when('forceRutWhenChile', {
        is: true,
        then: yup
          .string()
          .isValidDocument()
          .required(t('inputIsRequired', { field: t('document') })),
        otherwise: yup.string().when('hideDocumentField', {
          is: hideDocumentField => !hideDocumentField,
          then: yup
            .string()
            .isValidDocument()
            .required(t('inputIsRequired', { field: t('document') })),
          otherwise: yup.string(),
        }),
      }),
      isCustomAddress: yup.boolean(),
      tcAccept: yup
        .boolean()
        .required(t('mustAcceptTermAndConditions'))
        .oneOf([true], t('mustAcceptTermAndConditions')),
      showPaymentMethod: yup.boolean(),
      paymentMethod: yup
        .boolean()
        .required(t('mustHaveOnePaymentMethod'))
        .when('showPaymentMethod', {
          is: true,
          then: yup.boolean().oneOf([true], t('mustHaveOnePaymentMethod')),
        }),
      country: yup.string().when(['hideCountryField', 'showBillingFields'], {
        is: (hideCountryField, showBillingFields) => !hideCountryField || showBillingFields,
        then: yup.string().required(t('inputIsRequired', { field: t('country') })),
        otherwise: yup.string(),
      }),
      showBirthDateField: yup.boolean(),
      birthDay: yup.date().when('showBirthDateField', {
        is: true,
        then: yup
          .date()
          .birthDay()
          .required(t('inputIsRequired', { field: t('birthDay') })),
      }),
      addressNumber: yup.string().when('showBillingFields', {
        is: true,
        then: yup
          .string()
          .typeError(t('invalidNumber'))
          .required(t('inputIsRequired', { field: t('addressNumber') })),
      }),
      zipCode: yup.string().when(['showBillingFields', 'hideZipCode'], {
        is: (showBillingFields, hideZipCode) => showBillingFields && !hideZipCode,
        then: yup.string().required(t('inputIsRequired', { field: t('zipCode') })),
      }),
      typeId: yup.number().when('showBillingFields', {
        is: true,
        then: yup.number().required(t('inputIsRequired', { field: t('tipodocumento') })),
      }),
      fiscalConditionId: yup
        .string()
        .nullable()
        .when(['showBillingFields', 'hideFiscalCondition'], {
          is: (showBillingFields, hideFiscalCondition) => showBillingFields && !hideFiscalCondition,
          then: yup
            .string()
            .nullable()
            .hasValidDocumentType()
            .required(
              t('inputIsRequired', {
                field: t('billingInformation.fields.fiscalCondition'),
              }),
            ),
        }),
      city: yup.string().isValidCity(),
      password: yup.string().when('registerMe', {
        is: true,
        then: yup
          .string()
          .isValidPassword()
          .required(t('inputIsRequired', { field: t('myAccount.password') })),
      }),
      repeatPassword: yup.string().when('registerMe', {
        is: true,
        then: yup
          .string()
          .isValidRepeatPassword()
          .required(t('inputIsRequired', { field: t('myAccount.repeatPassword') })),
      }),
      isCompany: yup.boolean(),
      enableLicenseData: yup.boolean(),
      driverFirstName: yup.string().when('isCompany', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('name') })),
      }),
      driverLastName: yup.string().when('isCompany', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('lastname') })),
      }),
      driverEmail: yup
        .string()
        .email(t('validEmail'))
        .when('isCompany', {
          is: true,
          then: yup.string().required(t('inputIsRequired', { field: t('mail') })),
        }),
      driverPhone: yup.string().when('isCompany', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('phone') })),
      }),
      driverAddress: yup.string().when('isCompany', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('address') })),
      }),
      driverId: yup.string().when('isCompany', {
        is: true,
        then: yup
          .string()
          .isValidDriverDocument()
          .required(t('inputIsRequired', { field: t('document') })),
      }),
      driverCountry: yup.string().when(['isCompany', 'showBillingFields'], {
        is: (isCompany, showBillingFields) => isCompany && showBillingFields,
        then: yup.string().required(t('inputIsRequired', { field: t('country') })),
      }),
      driverAddressNumber: yup.string().when(['isCompany', 'showBillingFields'], {
        is: (isCompany, showBillingFields) => isCompany && showBillingFields,
        then: yup
          .string()
          .typeError(t('invalidNumber'))
          .required(t('inputIsRequired', { field: t('addressNumber') })),
      }),
      driverZipCode: yup.string().when(['isCompany', 'showBillingFields', 'hideZipCode'], {
        is: (isCompany, showBillingFields, hideZipCode) =>
          isCompany && showBillingFields && !hideZipCode,
        then: yup.string().required(t('inputIsRequired', { field: t('zipCode') })),
      }),
      driverTypeId: yup.number().when(['isCompany', 'showBillingFields'], {
        is: (isCompany, showBillingFields) => isCompany && showBillingFields,
        then: yup.number().required(t('inputIsRequired', { field: t('tipodocumento') })),
      }),
      driverCity: yup.string().isValidDriverCity(),
      companyFiles: yup.array().when(['loggedAsCompany', 'withPurchaseOrderFile'], {
        is: true,
        then: yup
          .array()
          .areCompanyFilesValid()
          .required(t('companyFilesError')),
      }),
      cityInput: yup.string().when(['enableCityInput'], {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('cityInput') })),
      }),
      stateInput: yup.string().when(['enableStateInput'], {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('stateInput') })),
      }),
      driverLicenseNumber: yup.string().when(['enableLicenseData'], {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('driverLicenseNumber') })),
      }),
      driverLicenseCountry: yup.string().when(['enableLicenseData'], {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('driverLicenseCountry') })),
      }),
      driverLicenseExpiration: yup.string().when('enableLicenseData', {
        is: true,
        then: yup
          .string()
          .required(t('inputIsRequired', { field: t('driverLicenseExpiration') }))
          .driverLicenseExpiration(
            this.props.bookingParams.dateTo,
            this.props.settings.featureFlags.licenseValidDays,
          ),
      }),
      withPurchaseOrder: yup
        .boolean()
        .nullable()
        .when(['loggedAsCompany', 'showCompanyExtraDocumentsFields', 'hidePurchaseOrderFields'], {
          is: (loggedAsCompany, showCompanyExtraDocumentsFields, hidePurchaseOrderFields) =>
            loggedAsCompany && showCompanyExtraDocumentsFields && !hidePurchaseOrderFields,
          then: yup
            .boolean()
            .nullable()
            .isWithPurchaseOrderSelected()
            .required(t('withPurchaseOrderError')),
        }),
      withPurchaseOrderFile: yup
        .boolean()
        .nullable()
        .when(['loggedAsCompany', 'withPurchaseOrder', 'showCompanyExtraDocumentsFields'], {
          is: true,
          then: yup
            .boolean()
            .nullable()
            .isWithPurchaseOrderFileUndefined(),
        }),
      notes: yup.string().when('requireNotes', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('notes') })),
      }),
    });
    this.formRef = React.createRef(null);
  }

  async componentDidMount() {
    const {
      fetchPrices,
      fetchBookingAdditionals,
      fetchDocumentTypes,
      fetchFiscalConditions,
      bookingParams,
      booking,
      i18n,
      searchCars: {
        checkout: { onlinePayment },
      },
      listDocumentTypes,
      listTaxPayerTypes,
      countries,
      fetchCountries,
      profile,
    } = this.props;

    if (this.state.userInfo.showBillingFields) {
      const tenantDocumentTypes =
        listDocumentTypes || (await fetchDocumentTypes(i18n.language)).payload;

      const tenantFiscalConditions =
        listTaxPayerTypes || (await fetchFiscalConditions(i18n.language))?.payload;

      this.setState(prevState => ({
        ...prevState,
        fiscalConditions: tenantFiscalConditions,
      }));

      this.setState(prevState => ({
        ...prevState,
        userInfo: { ...prevState.userInfo, tenantDocumentTypes },
      }));
    }

    if (!countries) await fetchCountries(i18n.language);

    if (booking && bookingParams) {
      const globalId = profile ? profile.globalId : bookingParams.agencyGuid ?? null;
      fetchBookingAdditionals(bookingParams, booking, i18n.language, globalId);
      fetchPrices(
        i18n.language,
        this.state.additionals,
        bookingParams,
        booking,
        false,
        onlinePayment,
        globalId,
      );
    }

    if (profile && !profile.isCompany && !profile.isAgency) {
      if (profile.country && profile.city) {
        const countryInfo = (countries || []).find(c => c.name === profile.country);
        if (countryInfo) await fetchCities(countryInfo.isoCode3166);
      }

      this.setState({
        ...this.state,
        userInfo: {
          ...this.state.userInfo,
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          id: profile.documentId || '',
          email: profile.emailAddress || '',
          address: profile.address || '',
          ...(profile.addressNumber && {
            addressNumber: profile.addressNumber,
          }),
          ...(profile.addressDepartment && {
            addressDepartment: profile.addressDepartment,
          }),
          ...(profile.documentTypeId && { typeId: profile.documentTypeId }),
          ...(profile.fiscalConditionId && {
            fiscalConditionId: profile.fiscalConditionId,
          }),
          ...(profile.cellPhone && { phone: profile.cellPhone }),
          ...(profile.zipCode && { zipCode: profile.zipCode || '' }),
          ...(profile.country && { country: profile.country }),
          ...(profile.city && { city: profile.city }),
          ...(profile.region && { region: profile.region }),
          ...(profile.state && { state: profile.state }),
          ...(profile.globalId && { globalId: profile.globalId }),
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      listAdditionals,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      isWidget,
    } = this.props;

    // Add required additional
    if (prevProps.listAdditionals !== listAdditionals && listAdditionals.length) {
      listAdditionals.forEach(a => {
        if (a.isRequired || a.isDefault) {
          this.onAdditionalChange({ id: a.id, number: 1, type: a.type });
        }
      });
    }

    if (prevProps.listAdditionals !== listAdditionals && is_gtag_enabled(googleTagManager)) {
      const groupedAdditionals = this.groupByAdditionals(listAdditionals);
      const additionalTypes = ['additional', 'insurance', 'other'];
      gtag_additional_impresions(groupedAdditionals, additionalTypes, settings);
      gtag_checkout_process({ number: 1, option: 'Additionals' }, this.props.booking, settings);
    }

    if (isWidget) {
      window.top.postMessage(
        {
          height: document.body.scrollHeight,
          width: document.body.scrollWidth,
        },
        '*',
      );
    }
  }

  debouncedAdditionalChange = debounce((additionals, actionObject) => {
    const {
      fetchPrices,
      searchCars: {
        params,
        checkout: { booking, onlinePayment },
      },
      settings: {
        configurations: { googleTagManager },
      },
      listAdditionals,
      bookingsConfiguration,
      profile,
      i18n,
      t,
    } = this.props;
    const globalId = profile ? profile.globalId : params.agencyGuid ?? null;
    const isInsuranceSelected = additionals.some(
      additional => additional.type.toLowerCase() === 'insurance',
    );
    // Update state isInsuranceSelected
    if (bookingsConfiguration?.mustHaveAtLeastOneInsurance) {
      this.setState({ isInsuranceSelected });
    }
    fetchPrices(i18n.language, additionals, params, booking, false, onlinePayment, globalId);

    if (is_gtag_enabled(googleTagManager) && actionObject) {
      const fullInfoAdditional = listAdditionals.find(add => add.id === actionObject.additionalId);
      gtag_manage_shopping_cart(fullInfoAdditional, actionObject, booking);
    }
  }, 500);

  getAdditionalInfo = id => {
    const { listAdditionals } = this.props;
    const additional = listAdditionals.find(add => add.id === id);

    if (additional) {
      return { ...additional, excludes: additional.excludes || [] };
    }
    return { excludes: [] };
  };

  onAdditionalChange = additional => {
    const exists = this.state.additionals.find(a => a.id === additional.id);

    let additionals = this.state.additionals;

    const actionObject = this.parseActionObject(exists, additional);

    if (exists) {
      const index = additionals.indexOf(exists);
      additionals.splice(index, 1);
    }

    if (additional.number > 0) {
      additionals.push(additional);

      // Removed excluded
      additionals = additionals.filter(a => {
        const notExcludedInCurrent = !this.getAdditionalInfo(additional.id).excludes.includes(a.id);
        const notExcludedInOthers = !this.getAdditionalInfo(a.id).excludes.includes(additional.id);

        return notExcludedInCurrent && notExcludedInOthers;
      });
    }
    this.setState({ additionals });
    this.debouncedAdditionalChange(additionals, actionObject);
  };

  isExcludedAdditional = additional => {
    return this.state.additionals.find(a => a.id === additional.id) ? false : true;
  };

  parseActionObject = (previousAdditional, currentAdditional) => {
    let action = null;

    if (!previousAdditional)
      return {
        type: 'add',
        amount: currentAdditional.number,
        additionalId: currentAdditional.id,
      };

    if (previousAdditional.number > currentAdditional.number)
      action = {
        type: 'remove',
        amount: currentAdditional.number,
        additionalId: currentAdditional.id,
      };

    if (currentAdditional.number > previousAdditional.number)
      action = {
        type: 'add',
        amount: currentAdditional.number,
        additionalId: currentAdditional.id,
      };

    return action;
  };

  logIn = () => {
    const profile = this.props.profile;
    this.setState({
      ...this.state,
      userInfo: {
        ...this.state.userInfo,
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        id: profile.documentId || '',
        email: profile.emailAddress || '',
        address: profile.address || '',
        ...(profile.addressNumber && {
          addressNumber: profile.addressNumber,
        }),
        ...(profile.addressDepartment && {
          addressDepartment: profile.addressDepartment,
        }),
        ...(profile.documentTypeId && { typeId: profile.documentTypeId }),
        ...(profile.fiscalConditionId && {
          fiscalConditionId: profile.fiscalConditionId,
        }),
        ...(profile.cellPhone && { phone: profile.cellPhone }),
        ...(profile.zipCode && { zipCode: profile.zipCode || '' }),
        ...(profile.country && { country: profile.country }),
        ...(profile.city && { city: profile.city }),
        ...(profile.region && { region: profile.region }),
        ...(profile.state && { state: profile.state }),
        ...(profile.globalId && { globalId: profile.globalId }),
        registerMe: false,
        loggedAsCompany: isCompanyUser(profile),
      },
      showLoginModal: false,
    });
  };

  logOut = async e => {
    e.preventDefault();
    await this.props.handleLogOut();

    const {
      addressNumber,
      addressDepartment,
      typeId,
      fiscalConditionId,
      zipCode,
      city,
      region,
      state,
      globalId,
      ...newUserInfo
    } = this.state.userInfo;

    this.setState({
      ...this.state,
      userInfo: newUserInfo,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { hideDocumentField, isOnRequest } = this.props;

    const idValue = hideDocumentField ? '' : this.state.userInfo.id;

    const userInfo = {
      ...this.state.userInfo,
      id: idValue,
      isOnRequest: isOnRequest,
    };
    let popUpErrorShow = this.state.popUpErrorShow;
    const {
      booking,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      searchCars: { params },
      handleLogIn,
      t,
      profile,
    } = this.props;
    this.userInfoFormSchema
      .validate(userInfo, { abortEarly: false })
      .then(async valid => {
        if (is_gtag_enabled(googleTagManager))
          gtag_checkout_process({ step: 2, option: 'Products selected' }, booking, settings);
        if (valid) {
          let userInfoWithGlobalId = {
            ...userInfo,
            driverAge: params.driverAge,
            profileIsCompany: profile && (profile.isCompany || profile.isAgency),
            profileGlobalId: profile && profile.globalId,
          };
          if (this.state.userInfo.registerMe) {
            try {
              this.setState({ formLoading: true });
              const response = await register(
                userInfo.firstName,
                userInfo.lastName,
                userInfo.email,
                userInfo.password,
              );
              if (response.globalId) {
                userInfoWithGlobalId = {
                  ...userInfoWithGlobalId,
                  globalId: response.globalId,
                };
              }
              await handleLogIn(userInfo.email, userInfo.password);
            } catch (error) {
              const message = error.response && error.response.data.message;
              this.setState({ formLoading: false });
              return alert(message || t('registerError'));
            }
          }
          this.book(userInfoWithGlobalId);
        }
      })
      .catch(err => {
        const formErrors = err.inner
          ? err.inner.reduce((prevErrors, currentError) => {
              return {
                ...prevErrors,
                [currentError.path]: currentError.message,
              };
            }, {})
          : {};
        window.scrollTo(0, this.formRef.current.offsetTop);
        popUpErrorShow = true;
        this.setState(prevState => ({
          ...prevState,
          formErrors,
          popUpErrorShow,
        }));
      });
  };

  handlesendQuotation = e => {
    e.preventDefault();
    let userInfo = this.state.userInfo;
    userInfo['isQuotation'] = true;
    this.setState({ formLoading: true });

    this.userInfoFormSchema
      .validate(userInfo, { abortEarly: false })
      .then(valid => {
        if (valid) this.sendQuotation(userInfo);
      })
      .catch(err => {
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({
          ...prevState,
          formErrors,
          formLoading: false,
        }));
      });
  };

  sendQuotation = info => {
    const {
      booking,
      book,
      bookingParams,
      t,
      i18n,
      settings,
      searchCars: { params },
    } = this.props;
    this.setState({ quotationLoading: true });

    const userInfo = { ...info, driverAge: params.driverAge };

    book(booking, userInfo, bookingParams, true, i18n.language)
      .then(res => {
        if (booking.onlyQuote)
          history.push(
            tlink('__Routes.toRequireSuccess', t, i18n, null, settings.configurations.langConfig),
          );

        history.push(
          tlink('__Routes.quotationSuccess', t, i18n, null, settings.configurations.langConfig),
        );
      })
      .catch(error => {
        history.push(
          tlink('__Routes.quotationFail', t, i18n, null, settings.configurations.langConfig),
        );
      });
  };

  book = info => {
    const {
      booking,
      book,
      bookingParams,
      t,
      onlinePayment,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      searchCars: { params },
      bookOnCounterNeedsConfirmation,
    } = this.props;

    bookingParams['onlinePayment'] = this.props.onlinePayment;
    this.setState({ formLoading: true });
    let state = this.state;
    let isPaymentsConfig =
      settings.paymentConfiguration && settings.paymentConfiguration.paymentMethods;

    var needsCreditCardInformation = bookOnCounterNeedsConfirmation;
    const userInfo = { ...info, driverAge: params.driverAge };

    book(booking, userInfo, bookingParams, false, i18n.language)
      .then(res => {
        if (onlinePayment) {
          if (isPaymentsConfig) {
            history.push(
              tlink('__Routes.onlinePayment', t, i18n, null, settings.configurations.langConfig),
              { paymentMethod: state.userInfo.paymentMethodObj.component },
            );
          } else {
            history.push(
              tlink('__Routes.onlinePayment', t, i18n, null, settings.configurations.langConfig),
            );
          }
          window.scrollTo(0, 0);
        } else {
          const subtitle = t('feedbackBoxMessages.book.successSubtitle', {
            booking_id: res.payload.id,
          });
          if (is_gtag_enabled(googleTagManager)) {
            booking.id = res.payload.id;
            booking.customer = res.payload.customer;
            gtag_purchase(booking, settings);
          }

          if (needsCreditCardInformation) {
            history.push(
              tlink('__Routes.onlinePayment', t, i18n, null, settings.configurations.langConfig),
              { paymentMethod: 'CreditCardConfirmationComponent' },
            );
          } else {
            history.push(
              tlink('__Routes.bookSuccess', t, i18n, null, settings.configurations.langConfig),
              { subtitle },
            );
          }
        }
      })
      .catch(error => {
        history.push(
          tlink('__Routes.bookFailed', t, i18n, null, settings.configurations.langConfig),
        );
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getBookingButtonText() {
    const {
      payBookingButtonText,
      bookBookingButtonText,
      t,
      onlinePayment,
      quoteBtn,
      searchCars: {
        checkout: { booking },
      },
    } = this.props;

    if (booking.onlyQuote) return t(quoteBtn) || t('quoteBooking');
    if (!onlinePayment) return bookBookingButtonText || t('bookBooking');
    return payBookingButtonText || t('payBooking');
  }

  getConditionsLink() {
    const { t, conditionsLink } = this.props;
    if (conditionsLink) {
      return t(conditionsLink);
    }
    return '/condiciones';
  }

  getPrivacyLink() {
    const { t, privacyLink } = this.props;
    if (privacyLink) {
      return t(privacyLink);
    }
    return '/privacidad';
  }

  openModal = () => {
    const modalState = true;
    const isCheckoutFilterVisible = false;

    this.setState({ modalState, isCheckoutFilterVisible });
  };

  closeModal = () => {
    const modalState = false;
    const isCheckoutFilterVisible = true;

    this.setState({ modalState, isCheckoutFilterVisible });
  };

  filterByAgreement = (additionals, commercialAgreementCode, commercialAgreementExtras) => {
    // if no commercial agreement code is provided, filter all commercialAgreementExtras
    const additionalList = additionals || [];
    const extras = commercialAgreementExtras || [];
    if (!commercialAgreementCode)
      return additionalList.filter(a => {
        return !extras.find(c => c.id === a.id);
      });

    const matchedExtrasIds = extras
      .filter(c => c.agreementId.includes(commercialAgreementCode))
      .map(c => c.id);

    // filter all commercialAgreementExtras except matched ones
    return additionalList.filter(a => {
      return !extras.find(c => c.id === a.id) || matchedExtrasIds.includes(a.id);
    });
  };

  groupByAdditionals = additionals => {
    const orderedListAdditionals = orderBy(
      additionals,
      ['isRequired', 'order', 'name'],
      ['desc', 'asc', 'asc'],
    );

    return groupBy(orderedListAdditionals, a => a.type.toLowerCase());
  };

  parseTermsAndConditionsBlock = () => {
    const { t, customTermsAndConditions } = this.props;

    if (customTermsAndConditions) {
      return (
        <span>
          <Paragraph text={customTermsAndConditions} />
        </span>
      );
    }

    return (
      <span>
        {t('acceptConditions')}
        <a href={this.getConditionsLink()} target="_blank" rel="noopener noreferrer">
          {t('purchaseConditions')}
        </a>
        &nbsp;{t('andalso')}&nbsp;
        <a href={this.getPrivacyLink()} target="_blank" rel="noopener noreferrer">
          {t('privacyPolicy')}
        </a>
      </span>
    );
  };

  getTemplateStyledComponent() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return StyledAdditionalsModern;
    }
    return StyledAdditionals;
  }

  getFirstColumnWidth() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-md-8';
    }
    return 'col-md-6';
  }

  getSndColumnWidth() {
    if (this.props.settings.theme.template === MODERN_THEME) {
      return 'col-md-4';
    }
    return 'col-md-6';
  }

  //#region Event OnChange

  onUserFirstNameChange = firstName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, firstName },
      formErrors: { ...prevState.formErrors, firstName: '' },
      popUpErrorShow: false,
    }));
  };

  onUserLastNameChange = lastName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, lastName },
      formErrors: { ...prevState.formErrors, lastName: '' },
      popUpErrorShow: false,
    }));
  };

  onUserBirthday = date => {
    const birthDay = date ? date.toISOString() : null;

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, birthDay },
      formErrors: { ...prevState.formErrors, birthDay: '' },
      popUpErrorShow: false,
    }));
  };

  onUserMailChange = email => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, email },
      formErrors: { ...prevState.formErrors, email: '' },
      popUpErrorShow: false,
    }));
  };

  onUserCountryChange = async country => {
    const { countries, cities, fetchCities } = this.props;
    const { userInfo } = this.state;

    if (country && (!cities || userInfo.country !== country)) {
      const countryInfo = countries.find(c => c.name === country);
      if (countryInfo) await fetchCities(countryInfo.isoCode3166);
    }

    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        country,
        city: '',
        region: '',
        state: '',
      },
      formErrors: { ...prevState.formErrors, country: '' },
      popUpErrorShow: false,
    }));
  };

  onUserDriverLicenseExpiration = date => {
    const driverLicenseExpiration = date ? date.toISOString() : null;

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverLicenseExpiration },
      formErrors: { ...prevState.formErrors, driverLicenseExpiration: '' },
      popUpErrorShow: false,
    }));
  };

  onUserDriverLicenseCountry = async (driverLicenseCountry, country) => {
    const { countries, cities, fetchCities } = this.props;
    const { userInfo } = this.state;

    if (country && (!cities || userInfo.driverLicenseCountry !== driverLicenseCountry)) {
      const countryInfo = countries.find(c => c.name === country);
      if (countryInfo) await fetchCities(countryInfo.isoCode3166);
    }

    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        driverLicenseCountry,
        city: '',
        region: '',
        state: '',
      },
      formErrors: { ...prevState.formErrors, driverLicenseCountry: '' },
      popUpErrorShow: false,
    }));
  };

  onUserDriverLicenseNumber = driverLicenseNumber => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverLicenseNumber },
      formErrors: { ...prevState.formErrors, driverLicenseNumber: '' },
      popUpErrorShow: false,
    }));
  };

  onUserCityInputChange = cityInput => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, cityInput },
      formErrors: { ...prevState.formErrors, cityInput: '' },
      popUpErrorShow: false,
    }));
  };

  onUserStateInputChange = stateInput => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, stateInput },
      formErrors: { ...prevState.formErrors, stateInput: '' },
      popUpErrorShow: false,
    }));
  };

  onUserPhoneChange = phone => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, phone },
      formErrors: { ...prevState.formErrors, phone: '' },
      popUpErrorShow: false,
    }));
  };

  onUserFligthNumberChange = flightNumber => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, flightNumber },
      formErrors: { ...prevState.formErrors, flightNumber: '' },
      popUpErrorShow: false,
    }));
  };

  onUserAddressChange = address => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, address },
      formErrors: { ...prevState.formErrors, address: '' },
      popUpErrorShow: false,
    }));
  };

  onUserCustomDeliveryAddressChange = customDeliveryAddress => {
    this.setState(prevState => {
      // Verificar si el campo customDeliveryAddress ha sido modificado y no está vacío
      const isCustomDeliveryAddress =
        customDeliveryAddress !== prevState.userInfo.customDeliveryAddress &&
        customDeliveryAddress !== '';

      return {
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          customDeliveryAddress,
          isCustomAddress: true,
          isCustomDeliveryAddress: isCustomDeliveryAddress,
        },
        formErrors: { ...prevState.formErrors, customDeliveryAddress: '' },
        popUpErrorShow: false,
      };
    });
  };

  onUserCustomDeliveryCityChange = customDeliveryCity => {
    this.setState(prevState => {
      const isCustomDeliveryCity =
        customDeliveryCity !== prevState.userInfo.customDeliveryCity && customDeliveryCity !== '';

      return {
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          customDeliveryCity,
          isCustomAddress: true,
          isCustomDeliveryCity: isCustomDeliveryCity,
        },
        formErrors: { ...prevState.formErrors, customDeliveryCity: '' },
        popUpErrorShow: false,
      };
    });
  };

  onUserCustomReturnAddressChange = customReturnAddress => {
    this.setState(prevState => {
      const isCustomReturnAddress =
        customReturnAddress !== prevState.userInfo.customReturnAddress &&
        customReturnAddress !== '';
      return {
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          customReturnAddress,
          isCustomAddress: true,
          isCustomReturnAddress: isCustomReturnAddress,
        },
        formErrors: { ...prevState.formErrors, customReturnAddress: '' },
        popUpErrorShow: false,
      };
    });
  };

  onUserCustomReturnCityChange = customReturnCity => {
    this.setState(prevState => {
      const isCustomReturnCity =
        customReturnCity !== prevState.userInfo.customReturnCity && customReturnCity !== '';
      return {
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          customReturnCity,
          isCustomAddress: true,
          isCustomReturnCity: isCustomReturnCity,
        },
        formErrors: { ...prevState.formErrors, customReturnCity: '' },
        popUpErrorShow: false,
      };
    });
  };

  onUserAddressNumberChange = addressNumber => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, addressNumber },
      formErrors: { ...prevState.formErrors, addressNumber: '' },
      popUpErrorShow: false,
    }));
  };

  onUserAddressDepartmentChange = addressDepartment => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, addressDepartment },
      formErrors: { ...prevState.formErrors, addressDepartment: '' },
      popUpErrorShow: false,
    }));
  };

  onUserZipCodeChange = zipCode => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, zipCode },
      formErrors: { ...prevState.formErrors, zipCode: '' },
      popUpErrorShow: false,
    }));
  };

  onUserIDChange = id => {
    let cleanId = id;
    const typeName = getDocumentTypeName(this.props.listDocumentTypes, this.state.userInfo.typeId);

    if (typeName === 'RUT') cleanId = cleanRUT(id);

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, id: cleanId },
      formErrors: { ...prevState.formErrors, id: '' },
      popUpErrorShow: false,
    }));
  };

  onUserCityChange = city => {
    const cityInfo = this.props.cities.find(c => c.name === city);

    if (cityInfo) {
      this.setState(prevState => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          city,
          region: cityInfo.region,
          state: cityInfo.state,
        },
        formErrors: { ...prevState.formErrors, city: '' },
        popUpErrorShow: false,
      }));
    }
  };

  onTCChange = tcAccept => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, tcAccept },
      formErrors: { ...prevState.formErrors, tcAccept: '' },
      popUpErrorShow: false,
    }));
  };

  onPaymentMethodChange = (paymentMethod, paymentMethodObj) => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, paymentMethod, paymentMethodObj },
      formErrors: { ...prevState.formErrors, paymentMethod: '' },
      popUpErrorShow: false,
    }));
  };

  onDocumentTypeChange = typeId => {
    const { listDocumentTypes } = this.props;

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, typeId },
      formErrors: {
        ...prevState.formErrors,
        typeId: '',
        fiscalConditionId: null,
      },
      popUpErrorShow: false,
      sampleDocFormat: getDocumentSample(listDocumentTypes, typeId),
    }));
  };

  onFiscalConditionChange = fiscalConditionId => {
    const {
      settings: { configurations },
    } = this.props;
    const validDocumentIds = (configurations?.fiscalConditionValidation || []).find(
      i => `${i.id}` === fiscalConditionId,
    )?.validDocumentIds;
    let typeId = this.state.userInfo.typeId;

    if (validDocumentIds && (!typeId || !validDocumentIds?.includes(typeId))) {
      typeId = validDocumentIds[0];
    }

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, fiscalConditionId, typeId },
      formErrors: {
        ...prevState.formErrors,
        fiscalConditionId: null,
        typeId: null,
      },
      popUpErrorShow: false,
    }));
  };

  onIsCompanyChange = isCompany => {
    const { listDocumentTypes } = this.props;

    let typeIdAux = this.state.userInfo.typeId;
    const docType = listDocumentTypes.find(f => f.name == 'RUT');

    if (docType !== undefined && isCompany) {
      typeIdAux = docType.id;
    }

    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        isCompany,
        typeId: typeIdAux,
      },
      formErrors: { ...prevState.formErrors, isCompany: '' },
      popUpErrorShow: false,
      sampleDocFormat: getDocumentSample(listDocumentTypes, typeIdAux),
    }));
  };

  onUserPasswordChange = password => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, password },
      formErrors: { ...prevState.formErrors, password: '' },
    }));
  };

  onUserRepeatPasswordChange = repeatPassword => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, repeatPassword },
      formErrors: { ...prevState.formErrors, repeatPassword: '' },
    }));
  };

  onDriverFirstNameChange = driverFirstName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverFirstName },
      formErrors: { ...prevState.formErrors, driverFirstName: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverLastNameChange = driverLastName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverLastName },
      formErrors: { ...prevState.formErrors, driverLastName: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverMailChange = driverEmail => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverEmail },
      formErrors: { ...prevState.formErrors, driverEmail: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverCountryChange = async driverCountry => {
    const { countries, cities, fetchCities } = this.props;
    const { userInfo } = this.state;

    if (driverCountry && (!cities || userInfo.driverCountry !== driverCountry)) {
      const countryInfo = countries.find(c => c.name === driverCountry);
      if (countryInfo) await fetchCities(countryInfo.isoCode3166);
    }

    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        driverCountry,
        driverCity: '',
        driverRegion: '',
        driverState: '',
      },
      formErrors: { ...prevState.formErrors, driverCountry: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverPhoneChange = driverPhone => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverPhone },
      formErrors: { ...prevState.formErrors, driverPhone: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverAddressChange = driverAddress => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverAddress },
      formErrors: { ...prevState.formErrors, driverAddress: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverAddressNumberChange = driverAddressNumber => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverAddressNumber },
      formErrors: { ...prevState.formErrors, driverAddressNumber: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverAddressDepartmentChange = driverAddressDepartment => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverAddressDepartment },
      formErrors: { ...prevState.formErrors, driverAddressDepartment: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverZipCodeChange = driverZipCode => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverZipCode },
      formErrors: { ...prevState.formErrors, driverZipCode: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverIDChange = driverId => {
    let cleanId = driverId;
    const typeName = getDocumentTypeName(
      this.props.listDocumentTypes,
      this.state.userInfo.driverTypeId,
    );

    if (typeName === 'RUT') cleanId = cleanRUT(driverId);

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverId: cleanId },
      formErrors: { ...prevState.formErrors, driverId: '' },
      popUpErrorShow: false,
    }));
  };

  onDriverCityChange = driverCity => {
    const cityInfo = this.props.cities.find(c => c.name === driverCity);

    if (cityInfo) {
      this.setState(prevState => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          driverCity,
          driverRegion: cityInfo.region,
          driverState: cityInfo.state,
        },
        formErrors: { ...prevState.formErrors, driverCity: '' },
        popUpErrorShow: false,
      }));
    }
  };

  onDriverDocumentTypeChange = driverTypeId => {
    const { listDocumentTypes } = this.props;

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, driverTypeId },
      formErrors: {
        ...prevState.formErrors,
        driverTypeId: '',
      },
      popUpErrorShow: false,
      sampleDriverDocFormat: getDocumentSample(listDocumentTypes, driverTypeId),
    }));
  };

  //#endregion

  //#region Company Events

  handleRequirePurchaseOrder = value => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        withPurchaseOrder: value,
        withPurchaseOrderFile: null,
        ...(!value && {
          purchaseOrder: '',
          companyFiles: [],
        }),
      },
      formErrors: {
        ...prevState.formErrors,
        withPurchaseOrder: '',
      },
    }));
  };

  handleWithPurchaseOrderFile = value => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        withPurchaseOrderFile: value,
        ...(!value && {
          purchaseOrder: '',
          companyFiles: [],
        }),
      },
      formErrors: {
        ...prevState.formErrors,
        withPurchaseOrderFile: '',
      },
    }));
  };

  onPurchaseOrderChange = purchaseOrder => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, purchaseOrder },
      formErrors: {
        ...prevState.formErrors,
        purchaseOrder: '',
      },
      popUpErrorShow: false,
    }));
  };

  onFileNameChange = (newName, index) => {
    const { userInfo } = this.state;

    const newFiles = userInfo.companyFiles.map((f, idx) => {
      if (index === idx) return { ...f, name: newName };
      return f;
    });

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, companyFiles: newFiles },
      formErrors: {
        ...prevState.formErrors,
        companyFiles: '',
      },
      popUpErrorShow: false,
    }));
  };

  handleDeleteFile = index => {
    const { userInfo } = this.state;

    const newFiles = userInfo.companyFiles.filter((f, idx) => idx !== index);

    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, companyFiles: newFiles },
      formErrors: {
        ...prevState.formErrors,
        companyFiles: '',
      },
      popUpErrorShow: false,
    }));
  };

  onCompanyFilesChange = async ({ currentTarget: { files } }) => {
    let base64Files = files;

    if (files) {
      const formattedFiles = [...files];

      base64Files = await Promise.all(
        formattedFiles.map(async file => {
          const base64 = await convertToBase64(file);
          return {
            name: file.name,
            file: base64.split('base64,') && base64.split('base64,')[1],
            type: 8, // Purchase Order type
          };
        }),
      );
    }

    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        companyFiles: base64Files,
      },
      formErrors: {
        ...prevState.formErrors,
        companyFiles: '',
      },
      popUpErrorShow: false,
    }));
  };

  onNotesChange = notes => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, notes },
      formErrors: {
        ...prevState.formErrors,
        notes: '',
      },
      popUpErrorShow: false,
    }));
  };

  //#endregion

  //#region Renders

  renderAddressFields = () => {
    const {
      t,
      addressText,
      addressNumberText,
      addressDepartmentText,
      zipCodeText,
      hideAddressField,
    } = this.props;
    const {
      userInfo: {
        showBillingFields,
        showAddressAllFields,
        country,
        address,
        addressNumber,
        addressDepartment,
        zipCode,
        hideZipCode,
        isCompany,
      },
    } = this.state;

    if (!showBillingFields && !showAddressAllFields)
      return (
        !hideAddressField && (
          <TextInput
            inputClass="form-control"
            placeholder={isCompany ? t('businessAddress') : t(addressText) || t('address')}
            error={this.state.formErrors.address}
            value={address}
            onChange={this.onUserAddressChange}
            options={{ autoComplete: 'address-line1' }}
            wrapperId="address-input"
            wrapperClass={country === 'Chile' ? 'col-md-5' : 'col-md-8'}
          />
        )
      );

    return (
      <>
        <TextInput
          inputClass="form-control"
          placeholder={isCompany ? t('businessAddress') : t(addressText) || t('addressName')}
          error={this.state.formErrors.address}
          value={address}
          onChange={this.onUserAddressChange}
          options={{ autoComplete: 'address-line1' }}
          wrapperId="address-input"
          wrapperClass="col-md-3"
        />
        <TextInput
          inputClass="form-control"
          placeholder={t(addressNumberText) || t('addressNumber')}
          value={addressNumber}
          error={this.state.formErrors.addressNumber || ''}
          onChange={this.onUserAddressNumberChange}
          options={{ autoComplete: 'address-line2' }}
          wrapperId="address_number-input"
          wrapperClass="col-md-2"
        />
        <TextInput
          inputClass="form-control"
          placeholder={t(addressDepartmentText) || t('addressDepartment')}
          error={this.state.formErrors.addressDepartment}
          value={addressDepartment}
          onChange={this.onUserAddressDepartmentChange}
          options={{ autoComplete: 'address-department' }}
          wrapperId="address_department-input"
          wrapperClass="col-md-2"
        />
        {!hideZipCode && (
          <TextInput
            inputClass="form-control"
            placeholder={t(zipCodeText) || t('zipCode')}
            error={this.state.formErrors.zipCode}
            value={zipCode}
            onChange={this.onUserZipCodeChange}
            options={{ autoComplete: 'postal-code' }}
            wrapperId="zip_code-input"
            wrapperClass="col-md-2"
          />
        )}
      </>
    );
  };

  renderDocumentFields() {
    const {
      t,
      documentText,
      listDocumentTypes,
      hideDocumentField,
      isAuthenticated,
      profile,
    } = this.props;
    const {
      userInfo: {
        showBillingFields,
        id,
        country,
        typeId,
        fiscalConditionId,
        isCompany,
        enableLicenseData,
        forceRutWhenChile,
        hideFiscalCondition,
      },

      docInputFocus,
      fiscalConditions,
    } = this.state;

    if (!showBillingFields)
      return (
        !hideDocumentField && (
          <TextInput
            inputClass="form-control"
            placeholder={
              documentText != undefined
                ? t(documentText)
                : enableLicenseData
                ? t('passportField')
                : t('document')
            }
            error={this.state.formErrors.id}
            value={id}
            onChange={this.onUserIDChange}
            options={{ autoComplete: 'document' }}
            legend={
              this.state.sampleDocFormat
                ? t('billingInformation.fields.formatExpected', {
                    format_expected: this.state.sampleDocFormat,
                  })
                : null
            }
            wrapperId="document-input"
            wrapperClass="col-md-9"
          />
        )
      );

    let rutDocumentType = this.state.userInfo.tenantDocumentTypes.filter(
      documentType => documentType.name === 'RUT',
    );

    return (
      <>
        {fiscalConditions && fiscalConditions.length > 0 && !hideFiscalCondition && (
          <SelectInput
            inputClass="form-control form-control-select"
            onChange={this.onFiscalConditionChange}
            selected={fiscalConditionId}
            options={transformFiscalConditionsToSelectOptions(fiscalConditions, t)}
            error={this.state.formErrors.fiscalConditionId}
            defaultOption={t('billingInformation.fields.fiscalCondition')}
            wrapperId="fiscal_condition-input"
            wrapperClass="col-md-3"
          />
        )}
        <SelectInput
          inputClass="form-control form-control-select"
          onChange={this.onDocumentTypeChange}
          selected={typeId}
          options={
            forceRutWhenChile && country === 'Chile'
              ? transformDocTypesToSelectOptions(
                  rutDocumentType.length > 0
                    ? rutDocumentType
                    : this.state.userInfo.tenantDocumentTypes,
                )
              : transformDocTypesToSelectOptions(this.props.listDocumentTypes) || []
          }
          error={this.state.formErrors.typeId}
          defaultOption={t('tipodocumento')}
          wrapperId="document_type-input"
          wrapperClass="col-md-3"
        />
        <TextInput
          inputClass="form-control"
          placeholder={isCompany ? t('businessDocument') : t(documentText) || t('document')}
          error={this.state.formErrors.id}
          value={isAuthenticated && !profile.isAgency ? id : null}
          onChange={this.onUserIDChange}
          options={{
            autoComplete: 'document',
            onFocus: () => {
              this.setState({ ...this.state, docInputFocus: true });
            },
            onBlur: () => {
              this.setState({ ...this.state, docInputFocus: false });
            },
          }}
          legend={
            this.state.sampleDocFormat
              ? t('billingInformation.fields.formatExpected', {
                  format_expected: this.state.sampleDocFormat,
                })
              : null
          }
          mask={getDocumentMask(id, typeId, docInputFocus, listDocumentTypes)}
          wrapperId="document-input"
          wrapperClass="col-md-3"
        />
      </>
    );
  }

  renderCompanyExtraDocumentsFields = () => {
    const { t, bookingBackupInformationTitle, notesText } = this.props;
    const {
      userInfo: {
        purchaseOrder,
        notes,
        loggedAsCompany,
        companyFiles,
        withPurchaseOrder,
        withPurchaseOrderFile,
        showCompanyExtraDocumentsFields,
        hidePurchaseOrderFields,
      },
      formErrors,
    } = this.state;

    if (this.props.profile && loggedAsCompany && showCompanyExtraDocumentsFields) {
      return (
        <>
          <div className="col-md-12">
            <h3 className="form-subtitle">
              {bookingBackupInformationTitle
                ? tt(bookingBackupInformationTitle, t)
                : t('confirmationBackup')}
            </h3>
          </div>
          {!hidePurchaseOrderFields && (
            <div className="col-md-12">
              <CheckboxInput
                onChange={() => this.handleRequirePurchaseOrder(true)}
                checked={withPurchaseOrder !== null ? withPurchaseOrder : undefined}
                text={t('bookingWithPO')}
                wrapperId="with_documents-input"
              />
              {withPurchaseOrder && (
                <>
                  <CheckboxInput
                    onChange={() => this.handleWithPurchaseOrderFile(true)}
                    checked={withPurchaseOrderFile !== null ? withPurchaseOrderFile : false}
                    text={t('attachPO')}
                    wrapperId="with_documents_file-input"
                    className="col-md-12"
                  />
                  <TextInput
                    inputClass="form-control"
                    placeholder={t('purchaseOrder')}
                    error={formErrors.purchaseOrder}
                    value={purchaseOrder}
                    onChange={this.onPurchaseOrderChange}
                    wrapperId="documents-input"
                    wrapperClass="col-md-12"
                  />
                  {companyFiles &&
                    companyFiles.map((file, idx) => {
                      return (
                        <div key={idx} className="col-md-12">
                          <div className="form-group file-input">
                            <TextInput
                              inputClass="form-control w-100"
                              placeholder={t('fileName')}
                              value={file.name}
                              onChange={value => this.onFileNameChange(value, idx)}
                            />
                            <button
                              className="delete-file"
                              onClick={() => this.handleDeleteFile(idx)}
                            >
                              <i className="fas fa-times-circle"></i>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  <div id="company_documents-input" className="col-md-12">
                    <div className="form-group">
                      <FormFile
                        className="file-input"
                        value=""
                        onChange={this.onCompanyFilesChange}
                        error={formErrors.companyFiles}
                      />
                      {formErrors.companyFiles && (
                        <div className="text-error">{formErrors.companyFiles}</div>
                      )}
                    </div>
                  </div>
                  <CheckboxInput
                    onChange={() => this.handleWithPurchaseOrderFile(false)}
                    checked={withPurchaseOrderFile !== null ? !withPurchaseOrderFile : false}
                    text={
                      <>
                        {t('takeBookingWithoutPO')}{' '}
                        <span className="text-error">{t('noPurchaseOrderUploaded')}</span>
                      </>
                    }
                    wrapperId="without_purchase_order-input"
                    className="col-md-12 mb-3"
                  />
                  {formErrors.withPurchaseOrderFile && (
                    <div className="col-md-12">
                      <p className="text-error">{formErrors.withPurchaseOrderFile}</p>
                    </div>
                  )}
                </>
              )}
              <CheckboxInput
                onChange={() => this.handleRequirePurchaseOrder(false)}
                checked={withPurchaseOrder !== null ? !withPurchaseOrder : undefined}
                text={t('bookingWithoutPO')}
                error={formErrors.withPurchaseOrder}
                wrapperId="without_documents-input"
              />
            </div>
          )}
          <TextAreaInput
            inputClass="form-control"
            placeholder={t(notesText) || t('notes')}
            error={formErrors.notes}
            value={notes}
            onChange={this.onNotesChange}
            wrapperId="notes-input"
            wrapperClass="col-md-12"
          />
        </>
      );
    }
    return null;
  };

  renderDriverFields = () => {
    const {
      t,
      cityText,
      settings: { featureFlags },
      hideCountryField,
    } = this.props;

    const {
      userInfo: {
        showBillingFields,
        driverFirstName,
        driverLastName,
        driverEmail,
        driverCountry,
        driverCity,
        driverPhone,
        isCompany,
      },
    } = this.state;

    if (isCompany)
      return (
        <>
          <div className="container-fluid p-0">
            <div className="col-md-6 mb-3 mt-1">
              <Title type="h2" text={t('DriverData')} weight="700" fontSize={16} italic={false} />
            </div>
          </div>
          <TextInput
            inputClass="form-control"
            placeholder={t('name')}
            error={this.state.formErrors.driverFirstName}
            value={driverFirstName}
            onChange={this.onDriverFirstNameChange}
            options={{ autoComplete: 'given-name' }}
            wrapperId="driver_first_name-input"
            wrapperClass="col-md-3"
          />
          <TextInput
            inputClass="form-control"
            placeholder={t('lastname')}
            error={this.state.formErrors.driverLastName}
            value={driverLastName}
            onChange={this.onDriverLastNameChange}
            options={{ autoComplete: 'family-name' }}
            wrapperId="driver_last_name-input"
            wrapperClass="col-md-3"
          />
          <TextInput
            inputClass="form-control"
            placeholder={t('mail')}
            error={this.state.formErrors.driverEmail}
            value={driverEmail}
            onChange={this.onDriverMailChange}
            options={{ autoComplete: 'email' }}
            wrapperId="driver_mail-input"
            wrapperClass="col-md-3"
          />
          {!hideCountryField && (
            <SelectInput
              inputClass="form-control form-control-select"
              onChange={this.onDriverCountryChange}
              selected={driverCountry || ''}
              options={transformCountriesToSelectOptions(this.props.countries) || []}
              error={this.state.formErrors.driverCountry}
              defaultOption={t('country')}
              wrapperId="driver_country-input"
              wrapperClass="col-md-3"
            />
          )}
          {driverCountry === 'Chile' && (
            <SelectInput
              inputClass="form-control form-control-select"
              onChange={this.onDriverCityChange}
              selected={driverCity || ''}
              options={this.props.cities ? this.props.cities.map(c => c.name) : []}
              error={this.state.formErrors.driverCity}
              defaultOption={t(cityText) || t('city')}
              wrapperId="driver_city-input"
              wrapperClass="col-md-3"
            />
          )}
          {this.renderDriverAddressFields()}
          <TextInput
            inputClass="form-control"
            placeholder={t('phone')}
            error={this.state.formErrors.driverPhone}
            value={driverPhone}
            onChange={this.onDriverPhoneChange}
            options={{ autoComplete: 'tel' }}
            wrapperId="driver_phone-input"
            wrapperClass={!showBillingFields ? 'col-md-4' : 'col-md-3'}
          />
          {this.renderDriverDocumentFields()}
        </>
      );
  };

  renderDriverAddressFields = () => {
    const { t, addressText, addressNumberText, addressDepartmentText, zipCodeText } = this.props;
    const {
      userInfo: {
        showBillingFields,
        driverCountry,
        driverAddress,
        driverAddressNumber,
        driverAddressDepartment,
        driverZipCode,
        hideZipCode,
      },
    } = this.state;

    if (!showBillingFields)
      return (
        <TextInput
          inputClass="form-control"
          placeholder={t(addressText) || t('address')}
          error={this.state.formErrors.driverAddress}
          value={driverAddress}
          onChange={this.onDriverAddressChange}
          options={{ autoComplete: 'address-line1' }}
          wrapperId="driver_address-input"
          wrapperClass={driverCountry === 'Chile' ? 'col-md-5' : 'col-md-8'}
        />
      );

    return (
      <>
        <TextInput
          inputClass="form-control"
          placeholder={t(addressText) || t('addressName')}
          error={this.state.formErrors.driverAddress}
          value={driverAddress}
          onChange={this.onDriverAddressChange}
          options={{ autoComplete: 'address-line1' }}
          wrapperId="driver_address-input"
          wrapperClass="col-md-3"
        />
        <TextInput
          inputClass="form-control"
          placeholder={t(addressNumberText) || t('addressNumber')}
          value={driverAddressNumber}
          error={this.state.formErrors.driverAddressNumber || ''}
          onChange={this.onDriverAddressNumberChange}
          options={{ autoComplete: 'address-line2' }}
          wrapperId="driver_address_number-input"
          wrapperClass="col-md-2"
        />
        <TextInput
          inputClass="form-control"
          placeholder={t(addressDepartmentText) || t('addressDepartment')}
          error={this.state.formErrors.driverAddressDepartment}
          value={driverAddressDepartment}
          onChange={this.onDriverAddressDepartmentChange}
          options={{ autoComplete: 'address-department' }}
          wrapperId="driver_address_department-input"
          wrapperClass="col-md-2"
        />
        {!hideZipCode && (
          <TextInput
            inputClass="form-control"
            placeholder={t(zipCodeText) || t('zipCode')}
            error={this.state.formErrors.driverZipCode}
            value={driverZipCode}
            onChange={this.onDriverZipCodeChange}
            options={{ autoComplete: 'postal-code' }}
            wrapperId="driver_zip_code-input"
            wrapperClass="col-md-2"
          />
        )}
      </>
    );
  };

  renderDriverDocumentFields = () => {
    const { t, documentText, listDocumentTypes } = this.props;
    const {
      userInfo: { showBillingFields, driverId, driverTypeId },
      docDriverInputFocus,
    } = this.state;

    if (!showBillingFields)
      return (
        <TextInput
          inputClass="form-control"
          placeholder={t(documentText) || t('document')}
          error={this.state.formErrors.driverId}
          value={driverId}
          onChange={this.onDriverIDChange}
          options={{ autoComplete: 'document' }}
          legend={
            this.state.sampleDriverDocFormat
              ? t('billingInformation.fields.formatExpected', {
                  format_expected: this.state.sampleDriverDocFormat,
                })
              : null
          }
          wrapperId="driver_document-input"
          wrapperClass="col-md-6"
        />
      );

    return (
      <>
        <SelectInput
          inputClass="form-control form-control-select"
          onChange={this.onDriverDocumentTypeChange}
          selected={driverTypeId}
          options={transformDocTypesToSelectOptions(this.props.listDocumentTypes) || []}
          error={this.state.formErrors.driverTypeId}
          defaultOption={t('tipodocumento')}
          wrapperId="driver_document_type-input"
          wrapperClass="col-md-3"
        />
        <TextInput
          inputClass="form-control"
          placeholder={t(documentText) || t('document')}
          error={this.state.formErrors.driverId}
          value={driverId}
          onChange={this.onDriverIDChange}
          options={{
            autoComplete: 'document',
            onFocus: () => {
              this.setState({ ...this.state, docDriverInputFocus: true });
            },
            onBlur: () => {
              this.setState({ ...this.state, docDriverInputFocus: false });
            },
          }}
          legend={
            this.state.sampleDriverDocFormat
              ? t('billingInformation.fields.formatExpected', {
                  format_expected: this.state.sampleDriverDocFormat,
                })
              : null
          }
          mask={getDocumentMask(driverId, driverTypeId, docDriverInputFocus, listDocumentTypes)}
          wrapperId="driver_document-input"
          wrapperClass="col-md-3"
        />
      </>
    );
  };

  //#endregion

  render() {
    const {
      listAdditionals,
      t,
      title,
      subtitle,
      settings: {
        featureFlags,
        paymentConfiguration,
        configurations: { commercialAgreementExtras },
      },
      searchCars: {
        checkout: { booking },
        params: { commercialAgreementCode },
      },
      onlinePayment,
      additionalComponentVersion,
      customerNameText,
      customerLastNameText,
      mailText,
      phoneText,
      flightNumberText,
      acceptConditionsLegend,
      usePhoneWithCountry,
      showPrintButton,
      showQuotationLink,
      insuranceMessage,
      cityText,
      isAuthenticated,
      profile,
      customerTitle,
      paymentTitle,
      hideCountryField,
      seeMore,
      seeLess,
      nameMaxLength,
      nameCharsSplit,
      insuranceImage,
      additionalImage,
      customAddressText,
      customAddressTexts,
      sendQuotationMsg,
      bookingsConfiguration,
      insuranceTitle,
      additionalTitle,
      hidePhoneField,
      notesText,
    } = this.props;

    const {
      formLoading,
      quotationLoading,
      popUpErrorShow,
      modalState,
      customReturn,
      customDelivery,
      userInfo: {
        showBillingFields,
        showAddressAllFields,
        firstName,
        lastName,
        email,
        country,
        city,
        cityInput,
        stateInput,
        phone,
        password,
        notes,
        repeatPassword,
        registerMe,
        hideFlightNumber,
        airlineField,
        showIsCompany,
        isCompany,
        loggedAsCompany,
        driverLicenseCountry,
        driverLicenseExpiration,
        driverLicenseNumber,
        birthDay,
        customDeliveryAddress,
        customReturnAddress,
        customDeliveryCity,
        customReturnCity,
      },
      showLoginModal,
      formErrors,
    } = this.state;

    if (booking == null) {
      return null;
    }

    let filteredAdditionals = [];
    let listAdditionalsByType = [];

    if (listAdditionals) {
      filteredAdditionals = this.filterByAgreement(
        listAdditionals,
        commercialAgreementCode,
        commercialAgreementExtras,
      );

      listAdditionalsByType = this.groupByAdditionals(filteredAdditionals);
    }

    const StyledSection = this.getTemplateStyledComponent();

    let showCustomAddressessFields = customDelivery || customReturn ? true : false;

    function CustomAddressAlert() {
      const customAddressAlertContent =
        customAddressTexts?.find(
          x => x.id === booking.deliveryPlace.id || x.id === booking.returnPlace.id,
        )?.text ||
        tt(customAddressText, t) ||
        t('customAddressAlertText');

      return (
        <Alert variant="warning">
          <p className="mb-0">{customAddressAlertContent}</p>
        </Alert>
      );
    }

    const additionalComponentVersionEnum = {
      1: 'CLASSIC',
      2: 'NEW',
    };

    return (
      <>
        <CheckoutFilter onClick={this.goBack} />
        <StyledSection className="no-print col-md-9">
          <Modal
            show={showLoginModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">{t('cancelbooking')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pl-5 pr-5">
              <LoginForm modal loginCallBack={this.logIn} />
            </Modal.Body>
            <Modal.Footer>
              <Btn
                onClick={() => this.setState({ ...this.state, showLoginModal: false })}
                text={t('cancel')}
              />
            </Modal.Footer>
          </Modal>

          <div className="checkout-additionals">
            <div className="row titleRow">
              <div className={`${this.getFirstColumnWidth()} first-col`}>
                <Title
                  type="h2"
                  text={title}
                  weight="900"
                  fontSize={30}
                  className={`${title != null ? '' : 'd-none'}`}
                />
                <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
              </div>
              <div className={`${this.getSndColumnWidth()} text-right mt-3`}>
                <a className="d-block othercar" onClick={this.goBack}>
                  <i className="fa fa-angle-double-left mr-2" />
                  {t('chooseOtherCar')}
                </a>
              </div>
            </div>

            {!listAdditionals && (
              <CustomLoading
                className="additionals-loader"
                loadingGif={t('loadingGif')}
              ></CustomLoading>
            )}
            <div className="row adicionales">
              {listAdditionalsByType?.insurance && (
                <>
                  <div>
                    <Title
                      className="additional-title"
                      type="h2"
                      text={insuranceTitle == null ? t('insurances') : tt(insuranceTitle, t)}
                      fontSize={20}
                      italic={false}
                    />
                    {insuranceMessage && <Paragraph className="label" text={insuranceMessage} />}
                    {bookingsConfiguration?.mustHaveAtLeastOneInsurance &&
                      !this.state.isInsuranceSelected && (
                        <div className="must-have-insurance-wrapper">
                          <i
                            className="fas fa-exclamation-circle"
                            data-toggle="tooltip"
                            title=""
                            data-original-title
                          />
                          <Paragraph
                            className="label"
                            color="red"
                            text={t('insuranceMustBeSelected')}
                          />
                        </div>
                      )}
                  </div>
                  {(!additionalComponentVersion ||
                    additionalComponentVersionEnum[additionalComponentVersion] === 'CLASSIC') && (
                    <table className="table table-hover adicionales-table">
                      <tbody>
                        {listAdditionalsByType?.insurance?.map((additional, key) => (
                          <AdditionalRow
                            key={key}
                            additional={additional}
                            value={this.state.additionals.find(a => a.id === additional.id)}
                            onAdditionalChange={this.onAdditionalChange}
                            seeMore={tt(seeMore, t)}
                            seeLess={tt(seeLess, t)}
                            nameMaxLength={tt(nameMaxLength, t)}
                            nameSplitNumber={tt(nameCharsSplit, t)}
                            currentCurrency={this.props.currentCurrency}
                            defaultCurrency={this.props.defaultCurrency}
                            webCurrency={this.props.webCurrency}
                          />
                        ))}
                      </tbody>
                    </table>
                  )}

                  {additionalComponentVersionEnum[additionalComponentVersion] === 'NEW' && (
                    <div className="additional-row-v2-wrapper">
                      {listAdditionalsByType?.insurance?.map((additional, key) => (
                        <AdditionalCard
                          key={key}
                          additional={additional}
                          value={this.state.additionals.find(a => a.id === additional.id)}
                          onAdditionalChange={this.onAdditionalChange}
                          seeMore={tt(seeMore, t)}
                          seeLess={tt(seeLess, t)}
                          insuranceImage={insuranceImage}
                          additionalImage={additionalImage}
                          nameMaxLength={tt(nameMaxLength, t)}
                          nameSplitNumber={tt(nameCharsSplit, t)}
                          currentCurrency={this.props.currentCurrency}
                          defaultCurrency={this.props.defaultCurrency}
                          webCurrency={this.props.webCurrency}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {listAdditionalsByType?.additional && (
                <>
                  <Title
                    className="additional-title"
                    type="h2"
                    text={additionalTitle == null ? t('additionals') : tt(additionalTitle, t)}
                    fontSize={20}
                    italic={false}
                  />
                  {(!additionalComponentVersion ||
                    additionalComponentVersionEnum[additionalComponentVersion] === 'CLASSIC') && (
                    <table className="table table-hover adicionales-table">
                      <tbody>
                        {listAdditionalsByType?.additional?.map((additional, key) => (
                          <AdditionalRow
                            key={key}
                            additional={additional}
                            value={this.state.additionals.find(a => a.id === additional.id)}
                            onAdditionalChange={this.onAdditionalChange}
                            seeMore={tt(seeMore, t)}
                            seeLess={tt(seeLess, t)}
                            nameMaxLength={tt(nameMaxLength, t)}
                            nameSplitNumber={tt(nameCharsSplit, t)}
                          />
                        ))}
                      </tbody>
                    </table>
                  )}

                  {additionalComponentVersionEnum[additionalComponentVersion] === 'NEW' && (
                    <div className="additional-row-v2-wrapper">
                      {listAdditionalsByType?.additional?.map((additional, key) => (
                        <AdditionalCard
                          key={key}
                          additional={additional}
                          value={this.state.additionals.find(a => a.id === additional.id)}
                          onAdditionalChange={this.onAdditionalChange}
                          seeMore={tt(seeMore, t)}
                          seeLess={tt(seeLess, t)}
                          insuranceImage={insuranceImage}
                          additionalImage={additionalImage}
                          nameMaxLength={tt(nameMaxLength, t)}
                          nameSplitNumber={tt(nameCharsSplit, t)}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {listAdditionalsByType?.other && (
                <>
                  <Title
                    className="additional-title"
                    type="h2"
                    text={t('others')}
                    fontSize={20}
                    italic={false}
                  />
                  <table className="table table-hover adicionales-table">
                    <tbody>
                      {listAdditionalsByType?.other?.map((additional, key) => (
                        <AdditionalRow
                          key={key}
                          additional={additional}
                          onAdditionalChange={this.onAdditionalChange}
                          value={this.state.additionals.find(a => a.id === additional.id)}
                          seeMore={tt(seeMore, t)}
                          seeLess={tt(seeLess, t)}
                          nameMaxLength={tt(nameMaxLength, t)}
                          nameSplitNumber={tt(nameCharsSplit, t)}
                        />
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div className="row user-info form-datos pt-1 mb-5" ref={this.formRef}>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-md-6 mb-3 mt-5">
                    <Title
                      type="h2"
                      text={customerTitle ? tt(customerTitle, t) : t('CustomerData')}
                      weight="700"
                      fontSize={20}
                      italic={false}
                    />
                  </div>
                  {featureFlags && featureFlags.customerRegistration && (
                    <div className="col-md-6 text-right register-user mt-5">
                      {isAuthenticated ? (
                        <button className="log-in-out-btn" onClick={this.logOut}>
                          <i class="fas fa-sign-out-alt"></i> {t('myAccount.logOut')}
                        </button>
                      ) : (
                        <button
                          className="log-in-out-btn"
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              showLoginModal: true,
                            })
                          }
                        >
                          <i className="fa fa-user" /> {t('registeredUserLink')}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <form
                id="bookingUserDataForm"
                onSubmit={booking.onlyQuote ? this.handlesendQuotation : this.handleSubmit}
                className="container-fluid p-0"
              >
                {showIsCompany && (!profile || !loggedAsCompany) && (
                  <div className="row">
                    <div className="col-md-12">
                      <CheckboxInput
                        error={this.state.formErrors.isCompany}
                        onChange={this.onIsCompanyChange}
                        checked={this.state.userInfo.isCompany}
                        text={t('isCompany')}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <TextInput
                    inputClass="form-control"
                    placeholder={
                      isCompany ? t('businessFantasyName') : t(customerNameText) || t('name')
                    }
                    error={this.state.formErrors.firstName}
                    value={isAuthenticated && !profile.isAgency ? firstName : null}
                    onChange={this.onUserFirstNameChange}
                    options={{ autoComplete: 'given-name' }}
                    wrapperId="first_name-input"
                    wrapperClass="col-md-3"
                  />
                  <TextInput
                    inputClass="form-control"
                    placeholder={
                      isCompany ? t('businessName') : t(customerLastNameText) || t('lastname')
                    }
                    error={this.state.formErrors.lastName}
                    value={isAuthenticated && !profile.isAgency ? lastName : null}
                    onChange={this.onUserLastNameChange}
                    options={{ autoComplete: 'family-name' }}
                    wrapperId="last_name-input"
                    wrapperClass="col-md-3"
                  />
                  {featureFlags && featureFlags.showBirthDateField && (
                    <DateInput
                      onDateChange={this.onUserBirthday}
                      onFocusChange={() => {}}
                      error={this.state.formErrors.birthDay}
                      placeholder={t('birthDay')}
                      id="birthDay-field"
                      showClearDate={true}
                      date={birthDay}
                      small={true}
                      wrapperId="birthday-input"
                      wrapperClass="col-md-3"
                    />
                  )}
                  <TextInput
                    inputClass="form-control"
                    placeholder={isCompany ? t('businessEmail') : t(mailText) || t('mail')}
                    error={this.state.formErrors.email}
                    value={isAuthenticated && !profile.isAgency ? email : null}
                    onChange={this.onUserMailChange}
                    options={{ autoComplete: 'email' }}
                    readOnly={isAuthenticated && !profile.isCompany && !profile.isAgency}
                    wrapperId="mail-input"
                    wrapperClass="col-md-3"
                  />
                  {!hideCountryField && (
                    <SelectInput
                      inputClass="form-control form-control-select"
                      onChange={this.onUserCountryChange}
                      selected={country || ''}
                      options={transformCountriesToSelectOptions(this.props.countries) || []}
                      error={this.state.formErrors.country}
                      defaultOption={t('country')}
                      wrapperId="country-input"
                      wrapperClass="col-md-3"
                    />
                  )}
                  {featureFlags && featureFlags.enableCityInput && country !== 'Chile' && (
                    <>
                      <TextInput
                        inputClass="form-control"
                        placeholder={t(cityInput) || t('cityInput')}
                        error={this.state.formErrors.cityInput}
                        value={cityInput}
                        onChange={this.onUserCityInputChange}
                        options={{ autoComplete: 'cityInput' }}
                        wrapperId="city-input"
                        wrapperClass="col-md-3"
                      />
                    </>
                  )}
                  {featureFlags && featureFlags.enableStateInput && country !== 'Chile' && (
                    <>
                      <TextInput
                        inputClass="form-control"
                        placeholder={t(stateInput) || t('stateInput')}
                        error={this.state.formErrors.stateInput}
                        value={stateInput}
                        onChange={this.onUserStateInputChange}
                        options={{ autoComplete: 'stateInput' }}
                        wrapperId="state-input"
                        wrapperClass="col-md-3"
                      />
                    </>
                  )}
                  {country === 'Chile' && (
                    <SelectInput
                      inputClass="form-control form-control-select"
                      onChange={this.onUserCityChange}
                      selected={city || ''}
                      options={this.props.cities ? this.props.cities.map(c => c.name) : []}
                      error={this.state.formErrors.city}
                      defaultOption={t(cityText) || t('city')}
                      wrapperId="chile_city-input"
                      wrapperClass="col-md-3"
                    />
                  )}
                  {featureFlags && featureFlags.enableLicenseData && (
                    <>
                      <TextInput
                        inputClass="form-control"
                        placeholder={t(driverLicenseNumber) || t('driverLicenseNumber')}
                        error={this.state.formErrors.driverLicenseNumber}
                        value={driverLicenseNumber}
                        onChange={this.onUserDriverLicenseNumber}
                        options={{ autoComplete: 'driverLicenseNumber' }}
                        wrapperId="driver_license_number-input"
                        wrapperClass="col-md-3"
                      />
                      <DateInput
                        onDateChange={this.onUserDriverLicenseExpiration}
                        onFocusChange={() => {}}
                        error={this.state.formErrors.driverLicenseExpiration}
                        placeholder={t('driverLicenseExpiration')}
                        id="driver-license-exp"
                        showClearDate={true}
                        date={driverLicenseExpiration}
                        small={true}
                        wrapperId="driver_license_expiration-input"
                        wrapperClass="col-md-3 DriverLicenseExpirationContainer"
                      />
                      <SelectInput
                        inputClass="form-control form-control-select"
                        onChange={this.onUserDriverLicenseCountry}
                        selected={driverLicenseCountry || ''}
                        options={transformCountriesToSelectOptions(this.props.countries) || []}
                        error={this.state.formErrors.driverLicenseCountry}
                        defaultOption={t('countryIssuing')}
                        wrapperId="driver_license_country-input"
                        wrapperClass="col-md-3"
                      />
                    </>
                  )}
                  {this.renderAddressFields()}
                  {!hidePhoneField &&
                    (usePhoneWithCountry ? (
                      <div
                        id="phone-input"
                        className={
                          !showBillingFields && !hideCountryField && !showAddressAllFields
                            ? 'col-md-4'
                            : 'col-md-3'
                        }
                      >
                        <PhoneInputWithCountrySelect
                          className="mb-2"
                          placeholder={t(phoneText) || t('phone')}
                          error={this.state.formErrors.phone}
                          value={phone}
                          onChange={this.onUserPhoneChange}
                          international
                        />
                        {this.state.formErrors.phone && (
                          <div className="text-error">{this.state.formErrors.phone}</div>
                        )}
                      </div>
                    ) : (
                      <TextInput
                        inputClass="form-control"
                        placeholder={t(phoneText) || t('phone')}
                        error={this.state.formErrors.phone}
                        value={phone}
                        onChange={this.onUserPhoneChange}
                        options={{ autoComplete: 'tel' }}
                        wrapperId="phone-input"
                        wrapperClass={
                          !showBillingFields && !hideCountryField && !showAddressAllFields
                            ? 'col-md-4'
                            : 'col-md-3'
                        }
                      />
                    ))}
                  {this.renderDocumentFields()}
                  {!hideFlightNumber && (
                    <TextInput
                      inputClass="form-control"
                      placeholder={
                        t(flightNumberText) || airlineField ? t('airline') : t('flightNumber')
                      }
                      error={this.state.formErrors.flightNumber}
                      onChange={this.onUserFligthNumberChange}
                      options={{ autoComplete: 'flight' }}
                      wrapperId="flight_number-input"
                      wrapperClass="col-md-3"
                    />
                  )}
                  {featureFlags.showNotesFieldOnCheckout && (
                    <TextAreaInput
                      inputClass="form-control"
                      placeholder={t(notesText) || t('notes')}
                      error={formErrors.notes}
                      value={notes}
                      onChange={this.onNotesChange}
                      wrapperId="notes-input"
                      wrapperClass="col-md-12"
                    />
                  )}
                  {this.renderDriverFields()}
                  {this.renderCompanyExtraDocumentsFields()}
                  {registerMe && (
                    <>
                      <TextInput
                        inputClass="form-control"
                        type="password"
                        className="form-control"
                        placeholder={t('myAccount.password')}
                        value={password}
                        onChange={this.onUserPasswordChange}
                        error={this.state.formErrors.password}
                        wrapperId="password-input"
                        wrapperClass="col-md-3"
                      />
                      <TextInput
                        inputClass="form-control"
                        type="password"
                        className="form-control"
                        placeholder={t('myAccount.repeatPassword')}
                        value={repeatPassword}
                        onChange={this.onUserRepeatPasswordChange}
                        error={this.state.formErrors.repeatPassword}
                        wrapperId="repeat_password-input"
                        wrapperClass="col-md-3"
                      />
                    </>
                  )}
                  {showCustomAddressessFields && (
                    <>
                      {CustomAddressAlert()}
                      <div className="container-fluid p-0 CustomAdressesContainer">
                        {customDelivery && (
                          <>
                            <TextInput
                              inputClass="form-control"
                              placeholder={t('customDeliveryCity')}
                              value={customDeliveryCity}
                              error={this.state.formErrors.customDeliveryCity}
                              onChange={this.onUserCustomDeliveryCityChange}
                              options={{ autoComplete: 'customDeliveryCity' }}
                              wrapperId="custom_delivery_city-input"
                              wrapperClass="col-md-3"
                            />
                            <TextInput
                              inputClass="form-control"
                              value={customDeliveryAddress}
                              placeholder={t('customDeliveryAddress')}
                              onChange={this.onUserCustomDeliveryAddressChange}
                              error={this.state.formErrors.customDeliveryAddress}
                              options={{ autoComplete: 'customDeliveryAddress' }}
                              wrapperId="custom_delivery_address-input"
                              wrapperClass="col-md-3"
                            />
                          </>
                        )}
                        {customReturn && (
                          <>
                            <TextInput
                              inputClass="form-control"
                              placeholder={t('customReturnCity')}
                              value={customReturnCity}
                              error={this.state.formErrors.customReturnCity}
                              onChange={this.onUserCustomReturnCityChange}
                              options={{ autoComplete: 'customReturnCity' }}
                              wrapperId="custom_return_city-input"
                              wrapperClass="col-md-3"
                            />
                            <TextInput
                              inputClass="form-control"
                              placeholder={t('customReturnAddress')}
                              value={customReturnAddress}
                              error={this.state.formErrors.customReturnAddress}
                              onChange={this.onUserCustomReturnAddressChange}
                              options={{ autoComplete: 'customReturnAddress' }}
                              wrapperId="custom_return_address-input"
                              wrapperClass="col-md-3"
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {featureFlags && featureFlags.customerRegistration && !isAuthenticated && (
                  <div className="row">
                    <div className="col-md-12 text-right register">
                      <div className="form-group">
                        <label className="special-checkbox d-inline">
                          <input
                            type="checkbox"
                            checked={registerMe}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                userInfo: {
                                  ...this.state.userInfo,
                                  registerMe: !registerMe,
                                },
                              })
                            }
                          />
                          <span className="checkmark" />
                          <span>
                            <strong>{t('registerme')}</strong>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </form>
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    {onlinePayment &&
                      paymentConfiguration &&
                      paymentConfiguration.paymentMethods && (
                        <PaymentMethods
                          checked={this.state.userInfo.paymentMethod}
                          error={this.state.formErrors.paymentMethod}
                          onChange={this.onPaymentMethodChange}
                          paymentMethods={paymentConfiguration.paymentMethods}
                          paymentTitle={paymentTitle}
                        />
                      )}
                    {!featureFlags?.hideTermsCheckbox && (
                      <div className="form-group conditions">
                        <CheckboxInput
                          error={this.state.formErrors.tcAccept}
                          onChange={this.onTCChange}
                          checked={this.state.userInfo.tcAccept}
                          text={this.parseTermsAndConditionsBlock()}
                        />
                        {acceptConditionsLegend &&
                          (!profile || profile.isCompany === false || profile.isAgency === false ? (
                            <Paragraph
                              className="conditions-legend"
                              text={acceptConditionsLegend}
                            />
                          ) : (
                            <Paragraph className="conditions-legend" text={''} />
                          ))}
                      </div>
                    )}
                    <div className="d-inline-flex">
                      <Btn
                        form="bookingUserDataForm"
                        type="submit"
                        text={formLoading ? t('creatingReservation') : this.getBookingButtonText()}
                        disabled={
                          formLoading ||
                          (bookingsConfiguration?.mustHaveAtLeastOneInsurance &&
                            !this.state.isInsuranceSelected)
                        }
                        className="checkout-submit-button mr-2"
                      />
                      {paymentConfiguration && paymentConfiguration.isQuotationHighligthed && (
                        <Btn
                          form=""
                          type=""
                          text={
                            quotationLoading
                              ? t('sending')
                              : sendQuotationMsg
                              ? tt(sendQuotationMsg, t)
                              : t('sendQuotation')
                          }
                          disabled={formLoading}
                          className="btn-reservation"
                          onClick={this.handlesendQuotation}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 send-budget">
                    {!booking.onlyQuote &&
                      !(paymentConfiguration && paymentConfiguration.isQuotationHighligthed) &&
                      !(showQuotationLink === false) && (
                        <a href="#" onClick={this.handlesendQuotation} className="mr-5">
                          <i className="far fa-envelope" />{' '}
                          {quotationLoading
                            ? t('sending')
                            : sendQuotationMsg
                            ? tt(sendQuotationMsg, t)
                            : t('sendQuotation')}
                        </a>
                      )}
                    {!(showPrintButton === false) && (
                      <a href="#" onClick={() => window.print()} className="print-btn">
                        <i className="fas fa-print" /> {t('print')}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledSection>
        <CheckoutMobileDetails
          popUpErrorShow={popUpErrorShow}
          disabled={
            formLoading ||
            (bookingsConfiguration?.mustHaveAtLeastOneInsurance && !this.state.isInsuranceSelected)
          }
          text={formLoading ? t('creatingReservation') : this.getBookingButtonText()}
          openModal={this.openModal}
          closeModal={this.closeModal}
          modalState={modalState}
        />
      </>
    );
  }
}

CheckoutAdditionals.propTypes = props;
CheckoutAdditionals.defaultProps = defaultProps;

const mapStateToProps = ({ siteConfiguration, searchCars, global, user, bookings }) => ({
  settings: siteConfiguration.settings,
  booking: searchCars.checkout.booking,
  onlinePayment: searchCars.checkout.onlinePayment,
  bookingParams: searchCars.params,
  listDocumentTypes: global.listDocumentTypes,
  countries: global.listCountries,
  cities: global.listCities,
  profile: user.profile,
  currentCurrency: global.currentCurrency,
  defaultCurrency: global.defaultCurrency,
  webCurrency: global.webCurrency,
  isAuthenticated: user.isAuthenticated,
  bookingsConfiguration: bookings.configuration,
});
export default connect(mapStateToProps, {
  book,
  fetchDocumentTypes,
  fetchFiscalConditions,
  fetchCountries,
  fetchCities,
  handleLogOut,
  handleLogIn,
})(withRouter(withTranslation()(CheckoutAdditionals)));
